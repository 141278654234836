import * as yup from "yup";
import i18n from "i18n/i18n";
import {urlValidationRegex} from "shared/constants";

const { t } = i18n;

const schema = {
  country: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.countryError")),
  cannabisCampaign: yup.string().notRequired(),
  goalValue: yup
  .number()
  .typeError(t('pages.xmo.solutionsStrategy.goal.goalValueValidNumberError'))
  .required(t('pages.xmo.solutionsStrategy.goal.goalValueErrorMessage'))
  .positive(),
  goalComment: yup
  .string()
  .required(t("pages.xmo.targetedDisplay.campaignInfo.goalCommentError"))
  .nullable(),
  targetingType: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.targetingTypeError"))
    .nullable(),
  countryToTarget: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.countryToTargetError"))
    .nullable(),
  statesToTarget: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.statesToTargetError"))
    .nullable(),
  metrosToTarget: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.metrosToTargetError"))
    .nullable(),
  radiusAddress: yup
    .string()
    .required(t("pages.targetedDisplay.solutionStrategy.radiusAddressError"))
    .nullable(),
  radiusSize: yup
    .number()
    .typeError(
      t("pages.targetedDisplay.solutionStrategy.radiusSizeFormatError")
    )
    .required(t("pages.targetedDisplay.solutionStrategy.radiusSizeError"))
    .min(10)
    .max(100)
    .nullable(),
  campaignRequestedStartDate: yup.string().required(t('pages.targetedDisplay.solutionStrategy.targetingTypeError')).nullable(),
  flightedCampaign: yup.string().required(t('pages.targetedDisplay.solutionStrategy.flightedCampaignError')).nullable(),
  flightStartDate: yup.string().notRequired().nullable(),
  flightEndDate: yup.string().notRequired().nullable(),
  campaignFlightDetails: yup.string().notRequired(),
  clientSuccessManagerEmail: yup
    .string()
    .required(
      t("pages.targetedDisplay.solutionStrategy.clientSuccessManagerEmailError")
    )
    .email(
      t("pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel")
    )
    .nullable(),
  campaignGoals: yup.array().of(yup.string()).min(1),
  crossMediaOptimization: yup.string().notRequired().nullable(),
  /*Web info*/
  rootWebsiteHomePageUrl: yup.string()
  .test('is-valid-url', t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired'), value => {
    if (!value) return true;
    return urlValidationRegex.test(value);
  }),
  highPriorityWebPageUrls: yup
    .string()
    .required(
      t(
        "pages.targetedDisplay.solutionStrategy.websiteInfo.highPriorityWebPageUrlsError"
      )
    )
    .nullable(),
  formSubmissionWebSiteUrls: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
