import {
  Box, Button, Card, CardContent, CardHeader, Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentMaintenanceMode } from 'services/maintenanceModeSlice';
import {CidCreation} from "pages/admin/CidCreation";

interface DashboardItem {
  name: string
  url: string
}

interface DashboardGroup {
  name: string
  items: DashboardItem[]
  additionalContent?: ReactNode
}

const DashboardGroupCard = ({ group }: { group: DashboardGroup }) => (
  <Card sx={{ width: '100%', marginTop: 4 }}>
    <CardHeader
      title={<Typography textTransform="uppercase" fontWeight="bold">{group.name}</Typography>}
      sx={{ borderBottom: '2px solid lightgrey', background: '#f9f4e8' }}
    />
    <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {group.additionalContent && group.additionalContent}
      {group.items.map(item => (
        <Link to={item.url} style={{ marginRight: '10px' }}>
          <Button variant="contained">
            <Typography>{item.name}</Typography>
          </Button>
        </Link>
      ))}
    </CardContent>
  </Card>
);

export default function Dashboard() {
  const maintenanceMode = getCurrentMaintenanceMode();
  const dashboardGroups: DashboardGroup[] = [
    {
      name: 'Maintenance Mode',
      items: [
        {
          name: 'Update Maintenance Mode',
          url: '/admin/maintenanceMode',
        },
      ],
      additionalContent: (
        <Box display="flex" marginRight={2}>
          <Typography>Maintenance Mode:&nbsp;</Typography>
          <Typography fontWeight="bold">{maintenanceMode}</Typography>
        </Box>
      ),
    },
    {
      name:'CID creation',
      items:[],
      additionalContent: <CidCreation/>,
    },
    {
      name: 'Themes',
      items: [
        {
          name: 'Themes',
          url: '/admin/themes',
        },
        {
          name: 'Theme Families',
          url: '/admin/families',
        },
        {
          name: 'Theme Features',
          url: '/admin/themeFeatures',
        },
        {
          name: 'Theme Benefits',
          url: '/admin/themeBenefits',
        },
        {
          name: 'Market Categories',
          url: '/admin/marketCategories',
        },
        {
          name: 'Bundles',
          url: '/admin/bundles',
        },
      ],
    },
    {
      name: 'Business Functions',
      items: [
        {
          name: 'Business Functionality',
          url: '/admin/businessFunctionalities',
        },
      ],
    },
    {
      name: 'Other',
      items: [
        {
          name: 'Capacity Header',
          url: '/admin/capacityHeaders',
        },
        {
          name: 'Update CID',
          url: '/admin/manage_cids',
        },
        {
          name: 'Sales Homepage',
          url: '/',
        },
      ],
    },
  ];

  return (
    <>
      <Typography variant="h2">Admin Dashboard</Typography>
      {dashboardGroups.map(group => <DashboardGroupCard group={group} key={group.name} />)}
    </>
  );
}
