import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  targetingType: yup.string().required(t('pages.youtube.targeting.targetingTypeErrorMsg')).nullable(),
  distance: yup.string().notRequired(),
  addressToUse: yup.string().notRequired(),
  additionalTargetingDetails: yup.string().notRequired(),
  areasToInclude: yup.string().notRequired(),
  areasToExclude: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
