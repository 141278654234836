import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import useMapboxService from 'services/MapboxService';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { getOrderBusinessByOrderId, selectOrderBusiness } from "services/businessSlice";
import { useParams } from "react-router";
import { getCountryCodeFromGmaid } from "shared/common";

export default function AddressField(props: any) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const [isTouched, setIsTouched] = useState(!!props.value);
  const [addressData, setAddressData] = useState<string[]>([]);
  const { getServiceAreas } = useMapboxService();
  const deBouncerRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const getSuggestions = async (input: string) => {
    const countryCode = getCountryCodeFromGmaid(content?.gmaid);
    try {
      const data = await getServiceAreas(input, countryCode);
      setAddressData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (value: string) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestions(value);
    }, 1500);
  };

  useEffect(() => {
    if (props.value) {
      handleInputChange(props.value);
    }
  }, [props.value]);

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: props?.rawErrors?.length > 0 && isTouched
            ? IQThemeV2.palette.error.main
            : IQThemeV2.palette.text.primary,
          marginBottom: '10px',
        }}
      >
        {props.label}
      </Typography>
      <Autocomplete
        value={props.value}
        disablePortal
        id="combo-box-demo"
        options={addressData}
        sx={{ width: '100%' }}
        onInputChange={(event, value) => {
          props.onChange(value);
          setIsTouched(true);
          handleInputChange(value);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      {props.placeholder && (props?.rawErrors?.length === 0 || !isTouched) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={props?.rawErrors?.length > 0 && isTouched}
        errorText={props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
