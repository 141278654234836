import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import CoFileList from 'components/fileList/CoFileList';
import BaseFileUploader from 'components/fileUploader/BaseFileUploader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFile, getFiles, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import { setFiles } from 'services/genericForm';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { useAppDispatch } from 'services/redux/store';

export default function FileUploader(props: any) {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const filesUploaded = useSelector(selectFilesUploaded);
  const [fileUploadedError, setFileUploadedError] = useState('');
  const [uploaderDirty, setUploaderDirty] = useState(false);
  const [prevFiles, setPrevFiles] = useState([]);

  const { uploaderType } = props;
  const filteredFilesUploaded = filesUploaded.content.filter(file => file.uploaderType === uploaderType);
  const megabyte = 1_000_000;
  const defaultFileSize = 20 * megabyte;

  const updateFiles = (files) => {
    dispatch(setFiles({
      [props.name]: files,
    }));
  };

  useEffect(() => {
    if (JSON.stringify(prevFiles) !== JSON.stringify(filteredFilesUploaded)) {
      setPrevFiles(filteredFilesUploaded);
      if (props?.onChange) {
        const fileData = filteredFilesUploaded.length ? JSON.stringify(filteredFilesUploaded) : '';
        props.onChange(fileData);
      }
    }
  }, [filteredFilesUploaded]);

  useEffect(() => {
    appDispatch(getFiles({ websiteId, uploaderType, offeringType }))
      .then((res) => updateFiles(res.payload));
  }, [websiteId]);

  const onUpdate = (newFiles) => {
    appDispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    })).then((res) => updateFiles(res.payload));
  };

  const handleUploaderError = () => {
    setFileUploadedError(filteredFilesUploaded.length === 0 ? 'Please upload the file to continue.' : '');
  };

  useEffect(() => {
    if (uploaderDirty) handleUploaderError();
  }, [uploaderDirty]);

  const onDelete = async (file) => {
    await dispatch(deleteFile({ websiteId, file }));
    await appDispatch(getFiles({ websiteId, uploaderType, offeringType }))
      .then((res) => updateFiles(res.payload));
    setUploaderDirty(true);
  };

  const gridItemStyles = {
    '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
    '& > div > div > *': { marginLeft: '10px' },
  };

  const gridListStyles = {
    '& ul.MuiList-root': { display: 'block' },
  };

  return (
    <Grid item xs={9} sx={gridItemStyles}>
      <BaseFileUploader
        showRecommendedImageText={false}
        showAcceptedFileTypesText
        required={props.required}
        name={props.name} maxFileSize={defaultFileSize}
        onSuccess={onUpdate}
        label={props.label}
        supportingText={props.supportingText}
        totalAllowedFiles={props.totalAllowedFiles}
        imageTypes={props.imageTypes}
        videoTypes={props.videoTypes}
        documentTypes={props.documentTypes}
        size="md"
        currentUploadedFiles={filteredFilesUploaded.length}
        onBlur={() => handleUploaderError()}
        requiredError={fileUploadedError}
      />
      <Grid item xs={9} mb={1}
        mt={0} sx={gridListStyles}
      >
        {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
        {filteredFilesUploaded.length
          ? <CoFileList files={filteredFilesUploaded} onDelete={onDelete} />
          : null}
      </Grid>
    </Grid>
  );
}
