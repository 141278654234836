import GridItem from 'components/grid/GridItem';
import { Box, Typography } from '@mui/material';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { headingStyle } from 'pages/overview/sections/Business/BusinessSection';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { getFiles, ProductTypes } from 'services/fileUploaderSlice';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { displayAssets } from 'pages/overview/sections/sites/SitesSection';
import { normalizeNewLines } from "shared/constants";
import {camelCaseToFlat} from "pages/shopping-cart/ShoppingCart";

type Props = {
  productData: any;
  isEditable: boolean;
  productId: number;
  offerId: number;
};

export default function XmoBrandAmplifierSection({
                                                   productData,
                                                   isEditable,
                                                   productId,
                                                   offerId,
                                                 }: Props) {
  const navigate = useNavigationHandler();
  const appDispatch = useAppDispatch();
  const [existingAssets, setExistingAssets] = useState([]);

  const getData = async () => {
    await appDispatch(getFiles({
      websiteId: offerId,
      uploaderType: ProductTypes.CLIENT_PROVIDED_KEYWORDS,
      offeringType: 'XMO',
    })).then((response) => setExistingAssets(response.payload));
  };

  useEffect(() => {
    if (offerId) {
      getData();
    }
  }, [offerId]);

  return (
    <>
      {/* Details */}
      <GridItem sizes={[3, 9]} marginBottom={0} sx={{
        padding: '20px 0 0',
        borderTop: '1px solid black',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          {editButton(isEditable, () => navigate.to(
            Urls.XmoDetails,
            productId.toString(),
          ))}
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
          >
            Details
          </Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Localiq Website</Typography>
                <Typography>{productData?.details?.clientHaveLocalIqWebsite}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Temporary Website URL</Typography>
                <Typography>
                  {displayNotRequiredField('Temporary Website URL', productData?.details?.temporaryWebsiteUrl)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Localiq Website Live</Typography>
                <Typography>
                  <Typography>
                    {displayNotRequiredField('Website Live', productData?.details?.localIqWebsiteLive)}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography sx={headingStyle} color="primary">Website Url</Typography>
                <Typography>
                  <Typography>
                    {displayNotRequiredField('Website Url', productData?.details?.websiteUrl)}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Solution Strategy */}
      <GridItem sizes={[3, 9]} marginBottom={3} sx={{
        padding: '20px 0 0',
        borderTop: '1px solid black',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
          >
            Solution Strategy
            <br/>
            (XMO, Brand Amplifier goal)
          </Typography>
        </Box>
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            {editButton(isEditable, () => navigate.to(
              Urls.XmoStrategyGoal,
              productId.toString(),
            ))}
            Goal
          </Typography>
          <GridItem sizes={[6, 6]} sx={{borderBottom: '1px solid #000000'}}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Goal Values
                </Typography>
                <Typography>{normalizeNewLines(productData?.solutionStrategy?.goalValue)}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Goal Comment
                </Typography>
                <Typography>
                  <Typography>
                    {normalizeNewLines(productData?.solutionStrategy?.goalComment)}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
      <GridItem sizes={[3, 9]} marginBottom={1}>
        <Box />
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            {editButton(isEditable, () => navigate.to(
              Urls.XmoStrategyTargeting,
              productId.toString(),
            ))}
            Service Area Targeting
          </Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Targeting Type</Typography>
                <Typography>{productData?.solutionStrategy?.targetingType}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Targeting Details</Typography>
                <Typography>
                  {displayNotRequiredField('Targeting Details', productData?.solutionStrategy?.targetingDetails)}
                </Typography>
              </Box>
              {(productData?.solutionStrategy?.targetingType === 'Zip/Postal Code') && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Zip Code Targeting Details</Typography>
                <Typography>
                  {productData?.solutionStrategy?.zipCodeTargetingDetails}
                </Typography>
              </Box>
              )}
              {(productData?.solutionStrategy?.targetingType === 'City') && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">City Targeting Details</Typography>
                    <Typography>
                      {productData?.solutionStrategy?.cityTargetingDetails === ""
                      || productData?.solutionStrategy?.cityTargetingDetails?.length === 0 ?
                          displayNotRequiredField(camelCaseToFlat("cityTargetingDetails"), "")
                          : productData?.solutionStrategy?.cityTargetingDetails?.length > 0
                          && productData?.solutionStrategy?.cityTargetingDetails?.map((item: any) =>
                              `${item?.city}, ${item?.state}`).join("; ")
                      }
                    </Typography>
                  </Box>
              )}
              {(productData?.solutionStrategy?.targetingType === 'DMA') && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">DMA Targeting Details</Typography>
                    <Typography>
                      {productData?.solutionStrategy?.dmaTargetingDetails === ""
                      || productData?.solutionStrategy?.dmaTargetingDetails?.length === 0 ?
                          displayNotRequiredField(camelCaseToFlat("dmaTargetingDetails"), "")
                          : productData?.solutionStrategy?.dmaTargetingDetails?.length > 0
                          && productData?.solutionStrategy?.dmaTargetingDetails?.map((item: any) =>
                              `${item?.name}, ${item?.provinceAbbreviation}`).join("; ")
                      }
                    </Typography>
                  </Box>
              )}
              {(productData?.solutionStrategy?.targetingType === 'Radius') && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Radius Mile/Kilometer Distance</Typography>
                <Typography>
                  {productData?.solutionStrategy?.radiusOrKilometerDistance}
                </Typography>
              </Box>
              )}
              {(productData?.solutionStrategy?.targetingType === 'Radius') && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Advertiser&apos;s Salesforce Business Address</Typography>
                <Typography>
                  {productData?.solutionStrategy?.salesforceBusinessAddress}
                </Typography>
              </Box>
              )}
            </Box>
            <Box>
              {(productData?.solutionStrategy?.targetingType === 'Radius')
                  && (productData?.solutionStrategy?.salesforceBusinessAddress === 'No') && (
                      <Box mb={2}>
                        <Typography sx={headingStyle} color="primary">New Business
                          Address</Typography>
                        <Typography>{productData?.solutionStrategy?.addressLine1}</Typography>
                        <Typography>{productData?.solutionStrategy?.addressLine2}</Typography>
                        <Typography>
                          {`${productData?.solutionStrategy?.city}, `
                              + `${productData?.solutionStrategy?.stateOrProvinceOrRegion}, ${
                                  productData?.solutionStrategy?.zipOrPostalCode}`}
                        </Typography>
                        <Typography>{productData?.solutionStrategy?.country}</Typography>
                      </Box>
                  )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Areas to Include that are NOT in Target Area
                </Typography>
                <Typography style={{whiteSpace: 'pre-wrap'}}>
                  {displayNotRequiredField(
                      'Areas to Include',
                      normalizeNewLines(productData?.solutionStrategy?.areasToIncludeNotInTargetArea),
                  )}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Areas to Exclude that are in Target Area
                </Typography>
                <Typography style={{whiteSpace: 'pre-wrap'}}>
                  {displayNotRequiredField(
                      'Areas to Exclude',
                      normalizeNewLines(productData?.solutionStrategy?.areasToExcludeInTargetArea),
                  )}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  TEM Count Tool Target Audience Demographic
                </Typography>
                <Typography style={{whiteSpace: 'pre-wrap'}}>
                  {displayNotRequiredField(
                      'Target Audience Demographic',
                      normalizeNewLines(productData?.solutionStrategy?.targetAudienceDemographic),
                  )}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
      <GridItem sizes={[3, 9]} marginBottom={1}>
        <Box />
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            {editButton(isEditable, () => navigate.to(
              Urls.XmoStrategyProxy,
              productId.toString(),
            ))}
            Proxy / Provisioning
          </Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Advertiser Email</Typography>
                <Typography>{productData?.solutionStrategy?.advertiserEmail}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Local Area Code Preference 1</Typography>
                <Typography>
                  {displayNotRequiredField(
                    'Local Area Code Preference 1',
                    productData?.solutionStrategy?.localAreaCodePreference1,
                  )}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Local Area Code Preference 2</Typography>
                <Typography>
                  {displayNotRequiredField(
                    'Local Area Code Preference 2',
                    productData?.solutionStrategy?.localAreaCodePreference2,
                  )}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Name For Call Verification</Typography>
                <Typography>{productData?.solutionStrategy?.businessNameForCallVerification}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Website Homepage URL</Typography>
                <Typography>{productData?.solutionStrategy?.businessWebsiteUrl}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Website Captcha Installed</Typography>
                <Typography>{productData?.solutionStrategy?.isCaptchaInstalled}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Another Account (MAID) tied to this campaign</Typography>
                <Typography>{productData?.solutionStrategy?.clientHaveAnotherMaidAccount}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  DO NOT Proxy the following areas of the website
                </Typography>
                {displayNotRequiredField(
                    'proxies of the website',
                    normalizeNewLines(productData?.solutionStrategy?.proxyTheFollowingAreas),
                )}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Select web event type</Typography>
                <Typography>{productData?.solutionStrategy?.webEventType}</Typography>
              </Box>
              {productData?.solutionStrategy?.webEventFields?.length > 0
                  && productData?.solutionStrategy?.webEventFields?.map((event: any, index: number) => (
                    <Box mb={2} key={event.sourceId}>
                      <Typography sx={headingStyle} color="primary">{`Web Event ${index + 1}`}</Typography>
                      <Typography>{`Value: ${event?.webEventValue}`}</Typography>
                      <Typography>{`Web Event Name: ${event?.webEventName}`}</Typography>
                      <Typography>{`Web Event URL: ${event?.webEventUrl}`}</Typography>
                    </Box>
                  ))}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Contact Info</Typography>
                <Typography>{productData?.solutionStrategy?.clientFullName}</Typography>
                <Typography>{productData?.solutionStrategy?.clientEmail}</Typography>
                <Typography>
                  {`${productData?.solutionStrategy?.clientPhoneNumber
                  } Ext. ${productData?.solutionStrategy?.extension}`}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Is this the best email for social mockup approval?
                </Typography>
                <Typography>{productData?.solutionStrategy?.isEmailForSocialMockupApproval}</Typography>
              </Box>
              {productData?.solutionStrategy?.isEmailForSocialMockupApproval === 'No' && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Social Mockup Approval Email
                </Typography>
                <Typography>{productData?.solutionStrategy?.socialMockupApprovalEmail}</Typography>
              </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Is this the best person to grant us Facebook Admin access?
                </Typography>
                <Typography>{productData?.solutionStrategy?.isPersonToGrantFbAdminAccess}</Typography>
              </Box>
              {productData?.solutionStrategy?.isPersonToGrantFbAdminAccess === 'No' && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Facebook Admin Access Email
                </Typography>
                <Typography>{productData?.solutionStrategy?.facebookAdminAccessEmail}</Typography>
              </Box>
              )}
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Tactics */}
      <GridItem sizes={[3, 9]} marginBottom={3} sx={{
        padding: '20px 0 0',
        borderTop: '1px solid black',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          {editButton(isEditable, () => navigate.to(
            Urls.XmoBaTactics,
            productId.toString(),
          ))}
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
          >
            Tactics
          </Typography>
        </Box>
        <Box>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            General Info
          </Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Inventory Tier</Typography>
                <Typography>{productData?.solutionStrategy?.inventoryTier}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Special Categories</Typography>
                <Typography>{productData?.solutionStrategy?.specialCategory}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Keywords</Typography>
                <Typography>{productData?.solutionStrategy?.keywords}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Pull Site Url</Typography>
                <Typography>
                  {displayNotRequiredField('Pull Site Url', productData?.solutionStrategy?.pullSiteUrl)}
                </Typography>
              </Box>
              {
                  (productData?.solutionStrategy?.keywords === 'Client-Provided')
                  && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Client Provided Keywords</Typography>
                    {displayAssets(existingAssets, 'Uploaded File')}
                  </Box>
                  )
              }
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Political Campaign?</Typography>
                <Typography>{productData?.solutionStrategy?.politicalCampaign}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Client Success Manager Email</Typography>
                <Typography>{productData?.solutionStrategy?.csmEmail}</Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
      <GridItem sizes={[3, 9]} marginBottom={1}>
        <Box />
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            Youtube Info
          </Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Youtube Video Url</Typography>
                <Typography>
                  {displayNotRequiredField(
                    'Youtube Video Url',
                    productData?.solutionStrategy?.youtubeVideoUrl,
                  )}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Youtube Video Urls</Typography>
                <Typography>
                  {displayNotRequiredField(
                    'Additional Youtube Video Urls',
                    productData?.solutionStrategy?.additionalYoutubeVideoUrls,
                  )}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
      <GridItem sizes={[3, 9]} marginBottom={1}>
        <Box />
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            Website Info
          </Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Website High Priority Page Urls
                </Typography>
                <Typography>{normalizeNewLines(productData?.solutionStrategy?.websiteHighPriorityUrls)}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Website Form Submission Urls
                </Typography>
                <Typography>{normalizeNewLines(productData?.solutionStrategy?.websiteFormUrls)}</Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );
}
