import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormInput,
  IQFormSelect,
  IQTheme, IQThemeV2,
  ValidationProvider
} from '@gannettdigital/shared-react-components';
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import Box from '@mui/material/Box';
import i18n from 'i18n/i18n';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import YouTubeType from "models/YouTubeType";
import schema from './Targeting.schema';
import GridItem from 'components/grid/GridItem';
import {
  targetingTypeDropdownItems,
  targetingTypeRadiusDistanceDropdownItems
} from "pages/youtube/targeting/TargetingTypeService";
import React, {
  useEffect,
  useRef,
  useState
} from "react";
import GridContainer from "components/grid/GridContainer";
import {yesNoDropdownItems} from "pages/youtube/YouTubeService";
import {
  Autocomplete,
  TextField,
  Typography
} from "@mui/material";
import useMapboxService from "services/MapboxService";
import CityTargetingDetails from "pages/generic-product/details/custom-fields/CityTargetingDetails";
import DmaTargetingDetails from "pages/generic-product/details/custom-fields/DmaTargetingDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrderBusinessByOrderId, selectOrderBusiness } from "services/businessSlice";
import { getCountryCodeFromGmaid } from "shared/common";

const {t} = i18n;

interface Props {
  product: YouTubeType;
  onSubmit: (data: TargetingPageFormProps) => void;
  onBack: () => void;
}

export type TargetingPageFormProps = {
  targetingType: string;
  cityTargetingDetails: any;
  dmaTargetingDetails: any;
  distance: string;
  additionalTargetingDetails: string;
  areasToInclude: string;
  areasToExclude: string;
  usingAdvertiserSalesforceBusinessAddress: string
  addressToUse: any
};

export default function TargetingPageForm({product, onSubmit, onBack}: Props) {
  const { getServiceAreas } = useMapboxService();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const [usingAdvertiserSalesforceBusinessAddressSelectedValue, setUsingAdvertiserSalesforceBusinessAddressSelectedValue] = useState(product?.usingAdvertiserSalesforceBusinessAddress || '');
  const [targetingTypeSelectedValue, setTargetingTypeSelectedValue] = useState<any>(product?.targetingType || '');
  const [places, setPlaces] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(product?.addressToUse);
  const [isRadiusDataRequired, setIsRadiusDataRequired] = useState<boolean>(false);
  const [cityDetails, setCityDetails] = useState<any>([]);
  const [dmaDetails, setDmaDetails] = useState<any>([]);
  const deBouncerRef = useRef<any>(null);

  const methods = useForm<TargetingPageFormProps>({
    mode: 'onChange',
    defaultValues: {
      targetingType: product?.targetingType || '',
      distance: product?.distance || '',
      addressToUse: product?.addressToUse || '',
      usingAdvertiserSalesforceBusinessAddress: product?.usingAdvertiserSalesforceBusinessAddress || '',
      additionalTargetingDetails: product?.additionalTargetingDetails || '',
      areasToInclude: product?.areasToInclude || '',
      areasToExclude: product?.areasToExclude || '',
    },
  });
  
  useEffect(() => {
    if (product?.cityTargetingDetails) setCityDetails(product?.cityTargetingDetails)
  }, [product]);
  
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const addressToUseValue = watch('addressToUse');

  useEffect(() => {
    if (targetingTypeSelectedValue.includes('Radius')) {
      setIsRadiusDataRequired(true);
      if (watch('distance') !== '') {
        if (usingAdvertiserSalesforceBusinessAddressSelectedValue === 'Yes') {
          setIsRadiusDataRequired(false);
        } else if (!(addressToUseValue === null || addressToUseValue === '')) {
          setIsRadiusDataRequired(false)
        }
      }
    } else {
      setIsRadiusDataRequired(false);
    }
  }, [targetingTypeSelectedValue, usingAdvertiserSalesforceBusinessAddressSelectedValue, addressToUseValue]);

  const handleFormSubmit = (data: TargetingPageFormProps) => {
    data.usingAdvertiserSalesforceBusinessAddress = usingAdvertiserSalesforceBusinessAddressSelectedValue;
    data.addressToUse = selectedAddress?.label;

    if (data.targetingType === 'Radius') {
      data.additionalTargetingDetails = '';
    } else if (data.targetingType === 'City') {
      data.distance = '';
      data.usingAdvertiserSalesforceBusinessAddress = '';
      data.addressToUse = '';
    } else if (data.targetingType === 'City' || data.targetingType === 'DMA') {
      data.distance = '';
      data.usingAdvertiserSalesforceBusinessAddress = '';
      data.addressToUse = '';
    } else if (data.targetingType === 'National') {
      data.additionalTargetingDetails = '';
      data.distance = '';
      data.usingAdvertiserSalesforceBusinessAddress = '';
      data.addressToUse = '';
    }

    if (data.usingAdvertiserSalesforceBusinessAddress === 'Yes' || (!data.addressToUse)) {
      data.addressToUse = '';
    }
    onSubmit(
        {
          ...data,
          cityTargetingDetails: data?.targetingType === 'City'? cityDetails : [],
          dmaTargetingDetails: data?.targetingType === 'DMA'? dmaDetails : [],
        }
    );
  }

  const handleTargetingTypeDropdownChange = (event) => {
    setTargetingTypeSelectedValue(event.target.value);
  }

  const handleUsingAdvertiserSalesforceBusinessAddressDropdownChange = (event) => {
    setUsingAdvertiserSalesforceBusinessAddressSelectedValue(event.target.value);
  };

  const handleInputChange = (value: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestions(value);
    }, 1500);
  };

  const getSuggestions = async (value: any) => {
    const countryCode = getCountryCodeFromGmaid(content?.gmaid);
    try {
      const address = await getServiceAreas(value, countryCode);
      setPlaces(address);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  return (
      <DefaultPageLayout
          disableContinue={!isValid || isRadiusDataRequired}
          onBack={onBack}
          header={t('pages.youtube.targeting.header')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="targetingType"
                      name="targetingType"
                      labelText={t('pages.youtube.targeting.targetingType')}
                      items={targetingTypeDropdownItems}
                      defaultValue=""
                      onChange={handleTargetingTypeDropdownChange}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                  />
                </GridItem>
                {targetingTypeSelectedValue === 'Radius' && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                            id="distance"
                            name="distance"
                            labelText={t('pages.youtube.targeting.distance')}
                            items={targetingTypeRadiusDistanceDropdownItems()}
                            defaultValue=""
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                            id="usingAdvertiserSalesforceBusinessAddress"
                            name="usingAdvertiserSalesforceBusinessAddress"
                            labelText={t('pages.youtube.targeting.usingAdvertiserSalesforceBusinessAddress')}
                            items={yesNoDropdownItems}
                            onChange={handleUsingAdvertiserSalesforceBusinessAddressDropdownChange}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>
                      {usingAdvertiserSalesforceBusinessAddressSelectedValue === 'No' &&
                          <>
                            <GridItem sizes={[12]} marginBottom={2}>
                              <Typography
                                  fontWeight={600}
                                  sx={{
                                    color: IQThemeV2.palette.text.primary,
                                  }}
                              >
                                {t('pages.youtube.targeting.addressToUse')}
                              </Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Autocomplete
                                  value={selectedAddress}
                                  disablePortal
                                  defaultValue={selectedAddress}
                                  id="addressToUse"
                                  options={places}
                                  sx={{
                                    width: '100%',
                                    border: '1px solid #292928',
                                    '& .MuiOutlinedInput-notchedOutline': {border: 0}
                                  }}
                                  onInputChange={(event, value) => {
                                    handleInputChange(value);
                                  }}
                                  onChange={(event, value) => {
                                    setSelectedAddress(value);
                                    setValue('addressToUse', value);
                                  }}
                                  renderInput={(params) => <TextField {...params}/>}
                              />

                            </GridItem>
                          </>
                      }
                    </>
                )}
                {targetingTypeSelectedValue === 'City' &&
                    <GridItem sizes={[12]} marginTop={-3}>
                      <CityTargetingDetails cityDetails={cityDetails}
                                            setCityDetails={setCityDetails}
                                            nonSchema={true}
                      />
                    </GridItem>
                }
                {targetingTypeSelectedValue === 'DMA' &&
                    <GridItem sizes={[12]} marginTop={-3}>
                      <DmaTargetingDetails dmaDetails={dmaDetails}
                                           setDmaDetails={setDmaDetails}
                                           nonSchema={true}
                      />
                    </GridItem>
                }
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="areasToInclude"
                      name="areasToInclude"
                      labelText={t('pages.youtube.targeting.areasToInclude')}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="areasToExclude"
                      name="areasToExclude"
                      labelText={t('pages.youtube.targeting.areasToExclude')}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                  />
                </GridItem>
              </GridContainer>
            </Box>
          </FormProvider>
        </ValidationProvider>
        <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </DefaultPageLayout>
  );
}
