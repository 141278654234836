import * as yup from "yup";
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  goalValue: yup.number().typeError(t('pages.xmo.solutionsStrategy.goal.goalValueValidNumberError')).required(t('pages.xmo.solutionsStrategy.goal.goalValueErrorMessage')).positive(),
  goalComment: yup.string().required(t('pages.xmo.solutionsStrategy.goal.commentValueErrorMessage')),
  campaignChannelLaunchStrategy: yup.string().required(t('pages.xmo.solutionsStrategy.goal.campaignChannelLaunchStrategyErrorMessage')),
}

export default {
  yupValidations: schema,
  customValidations: {},
};
