import { customLandingQuotesMenu } from 'services/menus/customLandingQuotesMenu';
import { websiteMenu } from 'services/menus/websiteMenu';
import { youTubeMenu } from 'services/menus/youtubeMenu';
import { targetedDisplayLegacyMenu } from 'services/menus/targetedDisplayLegacyMenu';
import { xmoUpgradeMenu } from 'services/menus/xmoUpgradeMenu';
import { promotionsMediaMixMenu } from 'services/menus/promotionsMediaMixMenu';
import { goldWebsiteMenu } from './goldWebsiteMenu';
import { standaloneAddonsMenu } from './standaloneAddonsMenu';
import { landingPageMenu } from './landingPageMenu';
import { ecommerceMenu } from './ecommerceMenu';
import { chatSilverMenu } from './chatSilverMenu';
import { chatPlatinumMenu } from './chatPlatinumMenu';
import { premiumListingsMenu } from './premiumListingsMenu';
import { customWebsiteMenu } from './customWebsiteMenu';
import { seoMenu } from './seoMenu';
import { customSeoMenu } from './customSeoMenu';
import { seoBlogStandardMenu } from './seoBlogStandardMenu';
import { seoBlogEnhancedMenu } from './seoBlogEnhancedMenu';
import { GenericProductMenu } from './genericProductMenu';
import { smmStandardMenu } from './smmStandardMenu';
import { smmCustomMenu } from './smmCustomMenu';
import { ccByDashMenu } from './ccByDashMenu';
import { customerCenterMenu } from './customerCenterMenu';
import { piqLandingPagesMenu } from './piqLandingPagesMenu';
import { targetedDisplayMenu } from './targetedDisplayMenu';
import { semMenu } from './semMenu';
import { xmoMenu } from './xmoMenu';

// This is required to ensure the sorting as per below array. (Product Requirement)
export const OfferingTypesWithMenus = [
  'PremiumListing',
  'CustomWebsite',
  'ChatProduct',
  'ChatPlatinumProduct',
  'Website',
  'GoldSite',
  'Addons',
  'MasterAddon',
  'EcommerceMicrosite',
  'LandingPage',
  'CustomLandingPage',
  'SEOStandard',
  'SEOCustom',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SEOTopicLocation',
  'SEOContentPages',
  'GenericProduct',
  'SMMStandard',
  'SMMCustom',
  'SMMFacebookPosts',
  'SMMInstagram',
  'SMMPinterest',
  'SMMX',
  'SMMInfographics',
  'DMSNextCustomerCenterByDash',
  'DMSNextCustomerCenter',
  'YouTube',
  'CustomTracking',
  'ShoppingAds',
  'Yelp',
  'SpanishChatAddon',
  'LocalServiceAds',
  'CustomReporting',
  'DisplayCreativeServices',
  'CustomSolutionsSnapchat',
  'CustomSolutionsSpotify',
  'ChatHybridAddon',
  'GoogleTagManager',
  'CustomSolutionsPandora',
  'VideoCreativeServicePackage',
  'CustomSolutionsTiktok',
  'SMBRetailGCIOnlyResponsiveSite',
  'SiteRetargetingWithLookalikesGDN',
  'SMBRetailGCIOnlySmartListings',
  'SMBRetailGCIOnlyVisiblePresence',
  'SiteRetargetingWithLookalikesGDN',
  'YouTube',
  'SiteRetargetingWithLookalikesGDN',
  'SMBRetailGCIOnly20PageSite',
  'SMBRetailGCIOnly5PageSite',
  'CustomSolutionsLinkedin',
  'SocialAdsLeadAdswithDetailedTargeting',
  'SocialAdsLeadAds',
  'TargetedEmail',
  'RLBrandedContent',
  'SocialAdsAdEngagementAddOn',
  'SearchEngineMarketing',
  'PiQLandingPages',
  'SocialAdsSmartOptimization',
  'TargetedDisplay',
  'TargetedDisplayLegacy',
  'SocialAdsCustom',
  'SocialAdsWebsiteClicks',
  'SocialAdsWebsiteClicksWithDetailedTargeting',
  'CRMIntegrationAddon',
  'CSWazePilot',
  'CustomCampaignLandingPages',
  'EnhancedReportingAddOn',
  'LocalServicesAdsManagementServices',
  'NationalSEM',
  'PerformanceMaxBeta',
  'WebsiteManagedServices',
  'WebsitesContentMigration',
  'PremiumListings10PlusPhysicianDirectories',
  'PremiumListings10Plus',
  'GetCreativeServiceFee',
  'SocialAdsRetargeting',
  'CustomSolutionsTwitter',
  'DisplayCreativeServiceCustom',
  'XMO',
  'XMOUpgrade',
  'WebsiteAddOnContentPages',
  'PromotionsPageAndCreativesReachLocalBetaOnly',
  'PromotionsMediaMix',
  'AUSTiktok',
  'AUSTotalTrack',
  'AUSLinkedin',
  'AUSCustomFacebook',
  'AUSPerformanceMax',
  'AUSYoutube',
  'AUSLiveChat',
  'MultiMediaCreatives',
  'AUSDemandGen',
  'AUSSite',
  'LandingPagesManagedServices',
  'AUSVideoCreativeService',
  'AUSGoogleDisplayNetwork',
  'AUSPremiumListings1To9',
  'AUSSocialCatalogue',
  'AUSXmoSocialUpgrade',
  'AUSCampaignLandingPages',
  'AUSSocialFBConversions',
  'GA4',
];

export const sortOrderItems = (orderItems, isSalesForce = false, isPunchlist = false) => {
  const menuItems = orderItems.filter(item => OfferingTypesWithMenus.includes(item.offeringType)
      || OfferingTypesWithMenus.includes(item.pdOfferingType));
  const filteredItems = (isSalesForce || isPunchlist)
    ? menuItems : menuItems.filter(item => item.offeringType !== 'PremiumListing');

  const orderMap = OfferingTypesWithMenus.reduce((acc, name, index) => {
    acc[name] = index;
    return acc;
  }, {});

  filteredItems.sort((a, b) => a.id - b.id);

  filteredItems.sort((a, b) => {
    const getOrderIndex = (item) => {
      if (orderMap[item.offeringType] !== undefined) {
        return orderMap[item.offeringType];
      }
      if (orderMap[item.pdOfferingType] !== undefined) {
        return orderMap[item.pdOfferingType];
      }
      return Number.MAX_VALUE;
    };

    const aIndex = getOrderIndex(a);
    const bIndex = getOrderIndex(b);

    return aIndex - bIndex;
  });

  return filteredItems;
};

export const getMenuStructure = (offeringType: string) => {
  switch (offeringType) {
    case 'Website':
      return websiteMenu;
    case 'GoldSite':
      return goldWebsiteMenu;
    case 'PremiumListing':
      return premiumListingsMenu;
    case 'CustomWebsite':
      return customWebsiteMenu;
    case 'ChatProduct':
      return chatSilverMenu;
    case 'ChatPlatinumProduct':
      return chatPlatinumMenu;
    case 'MasterAddon':
      return standaloneAddonsMenu;
    case 'EcommerceMicrosite':
      return ecommerceMenu;
    case 'LandingPage':
      return landingPageMenu;
    case 'CustomLandingPage':
      return customLandingQuotesMenu;
    case 'SEOStandard':
      return seoMenu;
    case 'SEOCustom':
      return customSeoMenu;
    case 'SEOBlogStandard':
      return seoBlogStandardMenu;
    case 'SEOBlogEnhanced':
      return seoBlogEnhancedMenu;
    case 'SMMStandard':
      return smmStandardMenu;
    case 'SMMCustom':
      return smmCustomMenu;
    case 'DMSNextCustomerCenterByDash':
      return ccByDashMenu;
    case 'DMSNextCustomerCenter':
      return customerCenterMenu;
    case 'YouTube':
      return youTubeMenu;
    case 'PiQLandingPages':
      return piqLandingPagesMenu;
    case 'TargetedDisplay':
      return targetedDisplayMenu;
    case 'TargetedDisplayLegacy':
      return targetedDisplayLegacyMenu;
    case 'SearchEngineMarketing':
      return semMenu;
    case 'XMO':
      return xmoMenu;
    case 'XMOUpgrade':
      return xmoUpgradeMenu;
    case 'PromotionsMediaMix':
      return promotionsMediaMixMenu;
    default:
      return GenericProductMenu;
  }
};

export default getMenuStructure;
