import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Chip,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  saveDmaTargetingDetails,
  selectDmaTargetingDetails,
} from "services/dmaTargetingdetailsSlice";
import { RootState } from "services/redux/store-schedule";
import AxiosAPI from "services/common/axios";
import { t } from "i18next";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorIcon from "@mui/icons-material/Error";
import { selectOrdersContent } from "services/ordersSlice";
import { getCurrentOffer, selectCurrentOffer } from "services/cefSlice";
import { useParams } from "react-router-dom";
import { OrderFlow } from "shared/constants";
import { getOfferIdByOfferName } from "services/offerMappingSlice";
import { selectProduct } from 'services/productsSlice';
import { getOrderBusinessByOrderId, selectOrderBusiness } from "services/businessSlice";
import { getCountryCodeFromGmaid } from "shared/common";

export default function DmaTargetingDetails(props: any) {
  const {data, stepper, dmaDetails, setDmaDetails, nonSchema} = props;
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const savedTargetingDetails = useSelector((state: RootState) =>
      selectDmaTargetingDetails(state)
  );
  const currentOrder = useSelector(selectOrdersContent);
  const product: any = useSelector(selectProduct);
  const currentOffer = useSelector(selectCurrentOffer);
  const [originalAddressData, setOriginalAddressData] = useState<any[]>([]);
  const [addressData, setAddressData] = useState<any[]>([]);
  const [items, setItems] = useState<any>(
      dmaDetails || savedTargetingDetails.dmaTargetingDetails || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemExistErrorMessage, setItemExistErrorMessage] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { orderItemId } = useParams();

  // For non-schema product
  useEffect(() => {
    if (items && nonSchema) {
      setItems(dmaDetails);
    }
  }, [dmaDetails]);

  useEffect(() => {
    if (nonSchema) setDmaDetails(items);
  }, [items, nonSchema]);

  // For schema product
  useEffect(() => {
    if (data?.dmaTargetingDetails) {
      setItems(data.dmaTargetingDetails);
    }
    if (stepper) props.onChange("");
  }, [data]);

  useEffect(() => {
    if (items && !nonSchema) {
      dispatch(
          saveDmaTargetingDetails({
            dmaTargetingDetails: items,
            dmsTargetingDetailsFlag: true,
          })
      );
    }
  }, [items, dispatch]);

  useEffect(() => {
    const filteredData = originalAddressData.filter((address: any) =>
        !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
    );
    setAddressData(filteredData);
  }, [items, originalAddressData]);

  useEffect(() => {
    dispatch(getCurrentOffer(orderItemId));
  }, [orderItemId]);

  const handleCitySelect = (selectedCity: any) => {
    if (!selectedCity) return;

    if (items.some((item: any) => item?.geoTargetId === selectedCity?.geoTargetId)) {
      setItemExistErrorMessage(true);
      return;
    }

    setItems((prevItems) => [...prevItems, selectedCity]);
    setItemExistErrorMessage(false);
  };

  const handleDelete = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    setItemExistErrorMessage(false);
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      setInputValue(value);
      const filteredData = originalAddressData.filter((address) =>
          address.name.toLowerCase().includes(value.toLowerCase())
      );
      const filteredItems = filteredData.filter((address: any) =>
          !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
      );
      setAddressData(filteredItems);
    } else if (reason === "clear") {
      setInputValue("");
      setAddressData(originalAddressData);
    }
  }

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const handleApiFetch = async () => {
    setIsLoading(true);
    try {
      const countryCode = getCountryCodeFromGmaid(content?.gmaid);
      const productName = product?.content?.productType?.name;
      let offerId;
      if (currentOrder?.orderFlow === OrderFlow.INTERNAL) {
        offerId = await getOfferIdByOfferName(productName, countryCode);
      } else {
        offerId = currentOffer?.salesForceOfferObject?.offerId;
      }
      const response: any = await AxiosAPI.get(
          `/public/dma/fetchDmasForProductId?productId=1:2:${offerId || 1}&countryCode=${countryCode}`
      );
      if (response.status === 200) {
        const data = response.data;
        const filteredData = data.filter((address: any) =>
            !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
        );
        setAddressData(filteredData);
        setOriginalAddressData(filteredData);
      }
      setItemExistErrorMessage(false);
    } catch (error) {
      setItemExistErrorMessage(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleApiFetch();
  }, [currentOffer, content]);

  return (
      <Grid container direction="column" spacing={2} mt={1}>
        <Grid item>
          <Typography variant="subtitle1" sx={{fontSize: "16px", fontWeight: "600"}}>
            {t("pages.localServiceAds.targetingType.dmaTargetingDetailsLabel")}
          </Typography>
        </Grid>
        <Grid item>
          <Autocomplete
              freeSolo
              inputValue={inputValue}
              options={addressData}
              getOptionLabel={(option) =>
                  typeof option === "string" ? option : `${option?.name}, ${option?.provinceAbbreviation}`
              }
              onInputChange={handleInputChange}
              onChange={(event, value) => {
                if (value && typeof value !== "string") {
                  handleCitySelect(value);
                }
                setInputValue((prev) => prev);
              }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      placeholder="Search Cities"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                              {isLoading ? <CircularProgress size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                        ),
                      }}
                  />
              )}
          />
          {itemExistErrorMessage && (
              <Box mt={1} sx={{display: "flex", alignItems: "center", color: "#C20F1E"}}>
                <ErrorIcon sx={{fontSize: "16px", marginRight: "5px"}}/>
                <Typography variant="subtitle1" sx={{fontSize: "14px"}}>
                  {t("pages.localServiceAds.targetingType.cityTargetingDetailsAlreadyAddedErrorMessage")}
                </Typography>
              </Box>
          )}
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
            {items.map((item, index) => (
                <Chip
                    key={index}
                    label={`${item?.name}, ${item?.provinceAbbreviation}`}
                    onDelete={() => handleDelete(index)}
                    deleteIcon={<CloseIcon style={{color: "#fff", fontSize: "18px"}}/>}
                    sx={{backgroundColor: "#0053F0", color: "#fff", fontSize: "14px"}}
                />
            ))}
            {items.length >= 2 && <Typography
                onClick={() => {
                  setItems([])
                }}
                sx={{
                  color: '#0053F0',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
            >
              {t("pages.localServiceAds.targetingType.clearAll")}
            </Typography>}
          </Box>
        </Grid>
      </Grid>
  );
}
