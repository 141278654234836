/* eslint-disable no-nested-ternary */
import React, { ComponentProps, useCallback, useEffect } from 'react';
import {
  FieldErrors, UseFormClearErrors, UseFormSetError, UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import styled, { StyledComponent } from '@emotion/styled';
import {
  IQCheckbox,
  IQDatePicker,
  IQFormInput,
  IQFormTextArea,
  IQTheme,
} from '@gannettdigital/shared-react-components';

import { useParams } from 'react-router';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { FormData } from '../PopUpForm';
import schema from '../PopUp.schema';

import { featuresTextAreas, formSectionCheckboxes } from './FormControlsList';

interface Props {
  StyledSubHeading: StyledComponent<any>;
  theme: typeof IQTheme;
  formValues: FormData;
  errors: FieldErrors<FormData>;
  setError: UseFormSetError<FormData | any>;
  clearErrors: UseFormClearErrors<FormData | any>;
  setValue: UseFormSetValue<FormData | any>;
}

type WrappedIQCheckboxProps = {
  className: string;
  theme: typeof IQTheme;
  isFirst?: boolean;
  isNested?: boolean;
  ml?: string;
} & ComponentProps<typeof IQCheckbox>;

const StyledLabel = styled(Typography)(
  ({ theme, isNested }: { theme: typeof IQTheme; isNested: boolean }) => `
  max-height: 24px;
  margin-bottom: ${isNested ? '8px' : '0'};
  font-family: ${theme.typography.fontFamily};
  font-weight: ${theme.typography.fontWeightBold};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${theme.palette.text.primary};
`,
);

const StyledSubLabel = styled(Typography)(
  ({ theme }: { theme: typeof IQTheme }) => `
  max-height: 20px;
  margin-bottom: 10px;
  font-family: ${theme.typography.fontFamily};
  font-weight: ${theme.typography.fontWeightRegular};
  font-size: 16px;
  line-height: 20px;
  color: ${theme.palette.text.primary};
`,
);

const WrappedIQCheckbox = (props: WrappedIQCheckboxProps) => (
  <div className={props.className}>
    <IQCheckbox {...props} />
  </div>
);

const StyledCheckbox = styled(WrappedIQCheckbox)(
  ({
    theme, isFirst, isNested, ml,
  }) => `
   color: ${theme.palette.text.primary};
   margin-top: ${isFirst ? '0' : '5px'};
   margin-top: ${isNested ? '-4px' : isFirst ? 0 : '5px'};
   margin-left: ${isNested ? 0 : '-4px'};
   margin-left: ${ml || '-4px'};
`,
);

const boldFontWeight = '600';

const FILE_UPLOADER_DIR = 'addons-popup';
const FILE_UPLOADER_TYPE = ProductTypes.POP_UP;
const megabyte = 1_000_000;
const defaultMaxFileSize = 20 * megabyte;

const fileUploaderProps: Pick<React.ComponentProps<typeof CoFileUploader>, 'fileUploaderOptions'> = {
  fileUploaderOptions: {
    directory: FILE_UPLOADER_DIR,
    name: 'popupImages',
    uploaderType: FILE_UPLOADER_TYPE,
    label: '',
    supportingText: '',
    showRecommendedImageText: false,
    imageTypes: ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'],
    videoTypes: [],
    documentTypes: [],
    maxFileSize: defaultMaxFileSize,
    showCheckboxes: false,
    showComments: false,
  },
};

const gettyProps: Pick<React.ComponentProps<typeof CoFileUploader>, 'gettyImagesUploaderOptions'> = {
  gettyImagesUploaderOptions: {
    name: 'popupGettyImages',
    productType: ProductTypes.POP_UP,
    showCheckboxes: false,
    showComments: false,
    displayOnly: 'images',
  },
};

const Features = ({
  StyledSubHeading, theme, formValues, errors, setError, clearErrors, setValue,
}: Props) => {
  const { t } = useTranslation();

  const { orderId, orderItemId } = useParams();

  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const {
    button, form, image, timer, other, firstName, lastName, email, phone, date,
  } = formValues;

  const evaluateFormCheckboxes = useCallback(() => {
    if (!form) return clearErrors('formError');

    if (!firstName && !lastName && !email && !phone && !other) {
      return setError('formError', { message: 'Please select at least one option' });
    }

    return clearErrors('formError');
  }, [firstName, lastName, email, phone, other, form]);

  const clearNestedValues = useCallback(() => {
    if (!form) {
      setValue('firstName', false);
      setValue('lastName', false);
      setValue('email', false);
      setValue('phone', false);
      setValue('other', false);
      setValue('otherText', '');
    }

    if (!button) {
      setValue('actionDetails', '');
    }

    if (!timer) {
      setValue('date', '');
    }
  }, [form, button, timer]);

  useEffect(() => {
    evaluateFormCheckboxes();
  }, [firstName, lastName, email, phone, other, form]);

  useEffect(() => {
    clearNestedValues();
  }, [form, button, timer]);

  const formSectionCheckboxesElements = formSectionCheckboxes.map((element) => (
    <StyledCheckbox
      {...(element.field === 'firstName' || element.field === 'lastName'
        ? { isFirst: true, ml: '4px' }
        : { isNested: true, ml: '4px' })}
      theme={IQTheme}
      className={`${element.field}-checkbox`}
      label={element.label}
      field={element.field}
      {...element}
    />
  ));

  const featuresTextAreaElements = featuresTextAreas.map((element) => (
    <Grid item sx={element.gridItemSx}>
      <IQFormTextArea
        subDescription={
          element.name === 'contentDesignInformation' ? errors?.contentDesignInformation?.message || '' : ''
        }
        {...element}
      />
    </Grid>
  ));

  return (
    <>
      <StyledSubHeading mt="39px" variant="h5" theme={theme}>
        {t('pages.addons.popUp.features.title')}
      </StyledSubHeading>
      <Grid sx={{ 'div > label': { pl: '4px' } }} container direction="column">
        <StyledLabel isNested={false} theme={theme}>
          {t('pages.addons.popUp.features.subtitle')}
        </StyledLabel>

        <Grid item flexDirection="column" display="flex"
          mt="20px"
        >
          <StyledCheckbox
            isFirst
            className="button-checkbox"
            theme={theme}
            label={`${t('pages.addons.popUp.features.button.checkboxLabel')}`}
            field="button"
            title=""
          />

          {button && (
            <Grid item sx={{ ml: '32px', 'div > label': { pl: 0, pb: '8px' }, div: { maxWidth: '416px' } }} mb="10px">
              <IQFormTextArea
                id="action-details-form"
                name="actionDetails"
                labelText={`${t('pages.addons.popUp.features.button.textAreaLabelText')} *`}
                required
                rowCount={2}
                fontLabelWeight={boldFontWeight}
                supportText={`${t('pages.addons.popUp.features.button.textAreaSupportText')}`}
                subDescription={errors.actionDetails?.message || ''}
              />
            </Grid>
          )}

          <StyledCheckbox
            className="form-checkbox"
            theme={theme}
            label={`${t('pages.addons.popUp.features.form.checkboxLabel')}`}
            field="form"
            title=""
          />

          {form && (
            <Grid
              item
              sx={{
                ml: '32px',
                'div > label': { pl: 0 },
                '&.MuiGrid-root': { maxWidth: '416px' },
              }}
              mb="10px"
            >
              <StyledLabel isNested theme={theme}>{`${t('pages.addons.popUp.features.subtitle')} *`}</StyledLabel>
              <StyledSubLabel theme={theme}>{`${t('pages.addons.popUp.features.form.subHeading')}`}</StyledSubLabel>
              <Grid
                item
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '8px 16px',
                  mt: '24px',
                }}
              >
                {formSectionCheckboxesElements}
              </Grid>
              {other && (
                <IQFormInput
                  id="other-text"
                  labelText=""
                  name="otherText"
                  theme={theme}
                  fullWidth
                  schema={schema}
                />
              )}
            </Grid>
          )}

          <StyledCheckbox
            className="image-checkbox"
            theme={theme}
            label={`${t('pages.addons.popUp.features.image.checkboxLabel')}`}
            field="image"
            title=""
          />

          {image && (
            <Grid item sx={{ ml: '32px', 'div > label': { pl: 0 }, '&.MuiGrid-root': { maxWidth: '608px' } }}>
              <StyledLabel theme={theme} isNested>
                {t('pages.addons.popUp.features.image.subHeading')}
              </StyledLabel>
              <StyledSubHeading mb="16px">{t('pages.addons.popUp.features.image.label')}</StyledSubHeading>
              <CoFileUploader
                orderId={orderId}
                orderItemId={orderItemId}
                productId={websiteId?.toString()}
                productType={ProductTypes.POP_UP}
                offeringType={offeringType as WebsiteOfferingTypes}
                schema={schema}
                showFileUploader
                showGetty
                fileUploaderOptions={fileUploaderProps.fileUploaderOptions}
                gettyImagesUploaderOptions={gettyProps.gettyImagesUploaderOptions}
                containerStyles={{
                  '& > div > div': { width: '100%' },
                  '& > div:first-child': { mb: '24px' },
                  '& > div:last-child': { mb: '12px' },
                  '& .MuiGrid-grid-xs-true': { alignContent: 'center' },
                  '& .MuiGrid-grid-xs-2': { m: 'auto' },
                  '& .MuiGrid-grid-xs-1': { display: 'flex', alignContent: 'center', justifyContent: 'flex-end' },
                }}
              />
            </Grid>
          )}

          <StyledCheckbox
            className="timer-checkbox"
            theme={theme}
            label={`${t('pages.addons.popUp.features.timer.checkboxLabel')}`}
            field="timer"
            title=""
          />

          {timer && (
            <Grid item sx={{ '&.MuiGrid-root': { maxWidth: '451px' }, 'div > label': { pl: 0 } }} mb="10px"
              pl="32px"
            >
              <IQDatePicker
                id="calendar"
                theme={theme}
                labelText={`${t('pages.addons.popUp.features.timer.datePickerLabelText')} *`}
                name="date"
                disablePast
                fullWidth
                fontLabelWeight={boldFontWeight}
                hasError={Date.now() > new Date(date).getTime()}
                openPickerOnClick
              />
            </Grid>
          )}
        </Grid>

        <Grid item width="451px" mt={-2}>
        {featuresTextAreaElements}
        </Grid>

        <Grid item width="451px" mt={5}>
          <IQFormTextArea
            id="additionalNotes"
            labelText="Additional Notes/Instructions"
            name="additionalNotes"
            rowCount={3}
            fullWidth
            fontLabelWeight="600"
            showError
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Features;
