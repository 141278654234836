/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Grid, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { getFiles, ProductTypes } from 'services/fileUploaderSlice';
import { camelCaseToFlat } from 'pages/shopping-cart/ShoppingCart';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getOrderItemSchema } from 'pages/generic-product/details/utils/functions';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { ActionItems } from '../common/ActionItems';
import { headingStyle } from '../Business/BusinessSection';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square
                  {...props}
    />
))(() => ({
  backgroundColor: 'transparent',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
}));

interface DayDetails {
  open: boolean;
  openTime: string;
  closeTime: string;
}

export default function SemSection({product, isEditable, showTracker}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const {orderId} = useParams();
  const [productData, setProductData] = useState({} as any);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUISchema, setCurrentUISchema] = useState(null);
  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const handleEditClick = (offeringType: string, productId: string) => {
    let url: Urls;
    switch (offeringType) {
      case 'SearchEngineMarketing':
        url = Urls.SemDetails;
        break;
      default:
        url = Urls.GenericProductDetails;
    }
    navigate.to(url, productId);
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => {
      setProductData(response.payload.data);
    });
  };

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.SOW_DOCUMENT,
      offeringType,
    }))
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
      getAssets(product?.offeringId, product.pdOfferingType);
      getOrderItemSchema({pdOfferingType: product.pdOfferingType}, () => {
      }, setCurrentUISchema, () => {
      }, {});
    }
  }, [product, orderId]);

  const renderObjectDetails = (data: any) => {
    const semSchema = [
      // Targeting Schema Fields
      "targetingType",
      "cityTargetingDetails",
      "zipCodeTargetingDetails",
      "dmaTargetingDetails",
      "additionalTargetingDetails",
      "radiusMileOrKilometerDistance",
      "advertiserSalesforceBusinessAddress",
      "businessAddressHeading",
      "businessAddress",
      "areasHeading",
      "areasToIncludeThatAreNotInTargetArea",
      "areasToExcludeThatAreNotInTargetArea",
      // Proxy Or Provisioning Schema Fields
      'clientHaveALocaliqWebsite',
      'localiqWebsite',
      'localAreaCodePreference1',
      'localAreaCodePreference2',
      'primaryNumberToBeProvisioned',
      'doesPrimaryBusinessNumberNeedToForward',
      'alternativeForwardingNumber',
      'utmDetailsHeading',
      'utmImplementString',
      'useThisUtm',
      'captureCodeCompatible',
      'isClientPartOfAFranchise',
      'reachCodeCompatibilityReportLink',
      'howToUseTheCapComToolIsAvailableHereLink',
      'websiteTracking',
      'websiteTrackingForFranchiseYes',
      'canWeStartYourCampaignOn3pp',
      'canWeStartYourCampaignOn3ppForFranchiseYes',
      'canWeStartYourCampaignOn3ppDescription1Label',
      'canWeStartYourCampaignOn3ppDescription2Label',
      'canWeStartYourCampaignOn3ppDescription3Label',
      'canWeStartYourCampaignOn3ppDescription4Label',
      'canWeStartYourCampaignOn3ppDescription5Label',
      'canWeStartYourCampaignOn3ppDescription6Label',
      'canWeStartYourCampaignOn3ppDescription7Label',
      'canWeStartYourCampaignOn3ppDescription8Label',
      'canWeStartYourCampaignOn3ppDescription9Label',
      'doesWebsiteHaveCaptchaInstalled',
      'captchaPiece',
      'doesClientHaveAnotherAccount',
      'doNotProxyWebsite',
      'helperText',
      'webEvents',
      'webEventItems',
      'webEventUrl',
      'webEventName',
      'value',
      'advertiserYelpProfileRatings',
      'yelpBusinessProfileUrl',
      'restrictedCategories',
      // SEM Keyword Schema Fields
      "generalHeading",
      "enableRemarketingListsForSearchAds",
      "flightedCampaign",
      "flightStartDate",
      "flightEndDate",
      "kpiValue",
      "accessToPreviousAdWordsAccount",
      "googleCustomerId",
      "keywordHeading",
      "keywordListCreation",
      "excludeFollowingKeywords",
      "includeFollowingNegativeKeywords",
      "additionalGeoKeywords",
      "excludeGeoKeywords",
      // SEM Ads Schema Fields
      "sitelinksHeading",
      "siteLinks",
      "sitelinks",
      "rsaHeading",
      "rsaTextAdPreference",
      "headlinesGuidlinesInfo",
      "descriptionGuidlinesInfo",
      "primaryAdGroupFocus",
      "primaryAdGroupLandingPage",
      "adGroups",
      "multipleLocationsHeading",
      "multipleLocations",
      "localExtensionSettings",
      "additionalNotes",
      "sitelinkName",
      "sitelinkUrl",
      "adGroupFocus",
      "adGroupLandingPage",
      "enableLocalServiceAds",
    ]

    const localServiceAdsSchema = [
      // Local Service Ads Schema Fields
      'selectPreferredBidModel',
      'professionalServicesClientSetMaxLabel',
      'professionalServicesClient',
      'category',
      'categoriesListAnchorText',
      'selectTheServicesYouWouldLikeToAdvertise',
      'generalLawyerQuerySearches',
      'fullBusinessName',
      'businessPhoneNumber',
      'businessWebsite',
      'businessOwnerFirstName',
      'businessOwnerLastName',
      'primaryClientEmailAddress',
      'totalNumberOfAttorneysOrLawyers',
      'licenseInformation',
      'gmbPageUrl',
      'totalNumberOfFieldworkers',
      'businessFoundedYear',
      'languagesSpoken',
      'professionalBusinessLicenseNumber',
      'professionalBusinessLicenseExpirationDate',
      'insurancePolicyDocumentsAttached',
      'insurancePolicyDocumentsLabelSelectedNo',
      'schedule',
      'notesOrInstructions',
    ]

    return (
        <Box mb={2}>
          {
              data?.enableLocalServiceAds === "Yes"
              && <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <Typography sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                  marginBottom: '20px',
                  paddingBottom: '10px',
                  borderBottom: '1px solid #000000'
                }}>
                  Search Engine Marketing
                </Typography>
              </Box>
          }
          <Grid container spacing={0}>
            {semSchema.map((key: any) => {
              if (key === 'cityTargetingDetails') {
                return (
                    <Grid item xs={6} key={key}>
                      <Box key={key} mb={2} mr={2}>
                        <Typography sx={headingStyle}
                                    color="primary">{camelCaseToFlat(key)}</Typography>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {data[key] === "" ?
                            displayNotRequiredField(camelCaseToFlat(key), data[key])
                            : data[key]?.length > 0
                            && data[key]?.map((item: any) =>
                                `${item?.city}, ${item?.state}`).join("; ")
                        }
                      </Box>
                    </Grid>
                )
              }
              if (key === 'dmaTargetingDetails') {
                return (
                    <Grid item xs={6} key={key}>
                      <Box key={key} mb={2} mr={2}>
                        <Typography sx={headingStyle}
                                    color="primary">{camelCaseToFlat(key)}</Typography>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {data[key] === "" ?
                            displayNotRequiredField(camelCaseToFlat(key), data[key])
                            : data[key]?.length > 0
                            && data[key]?.map((item: any) =>
                                `${item?.name}, ${item?.provinceAbbreviation}`).join("; ")
                        }
                      </Box>
                    </Grid>
                )
              }
              return (
                  (!(key === 'webEventItems' || key === 'adGroups' || key === 'primaryAdGroupFocus' || key === 'primaryAdGroupLandingPage') && (data[key] || data[key] === "")) && (
                      <Grid item xs={6} key={key}>
                        <Box mb={2}>
                          <Typography sx={headingStyle}
                                      color="primary">{camelCaseToFlat(key)}</Typography>
                          <Typography>{displayNotRequiredField(camelCaseToFlat(key), data[key])}</Typography>
                        </Box>
                      </Grid>
                  )
              )
            })}
          </Grid>
          <Grid container spacing={0}>
            {semSchema.map((key: any) => {
              if ((key === 'webEventItems' || key === 'adGroups') && (data[key] || data[key] === "")) {
                const value = data[key];
                if (value === "") {
                  return (
                      <Grid item xs={12} key={key}>
                        <Box mb={2}>
                          <Typography sx={headingStyle}
                                      color="primary">{camelCaseToFlat(key)}</Typography>
                          <Typography>{displayNotRequiredField(camelCaseToFlat(key), data[key])}</Typography>
                        </Box>
                      </Grid>
                  )
                }
                return (
                    <>
                      <Box my={2}>
                        <Typography sx={headingStyle}
                                    color="primary">{camelCaseToFlat(key)}</Typography>
                      </Box>
                      {
                          key === 'adGroups' &&
                          <Grid container spacing={2}>
                            <Grid item xs={6} key={key}>
                              <Box mx={2}>
                                <Typography sx={headingStyle}
                                            color="primary">{camelCaseToFlat('primaryAdGroupFocus')}</Typography>
                                <Typography>{displayNotRequiredField(camelCaseToFlat('primaryAdGroupFocus'), data['primaryAdGroupFocus'])}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} key={key}>
                              <Box mx={2}>
                                <Typography sx={headingStyle}
                                            color="primary">{camelCaseToFlat('primaryAdGroupLandingPage')}</Typography>
                                <Typography>{displayNotRequiredField(camelCaseToFlat('primaryAdGroupLandingPage'), data['primaryAdGroupLandingPage'])}</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                      }
                      {
                        value?.map((item: any, idx: number, valueArr: any[]) => (
                            <Grid item xs={12} key={key}>
                              <Accordion key={idx} defaultExpanded>
                                <AccordionSummary
                                    aria-controls={`panel${idx}-content`}
                                    id={`panel${idx}-header`}
                                >
                                  <Typography>
                                    {`${camelCaseToFlat(key)} `}
                                    {valueArr.length !== 1 && idx + 1}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box sx={{paddingLeft: '15px'}}>
                                    <Grid container spacing={2}>
                                      {Object.keys(item)?.map((el: any) => (
                                          <Grid item xs={6}
                                                key={el}>
                                            <Typography sx={headingStyle} color="primary">
                                              {camelCaseToFlat(el)}
                                            </Typography>
                                            <Typography>{displayNotRequiredField(camelCaseToFlat(el), item[el])}</Typography>
                                          </Grid>
                                      ))}
                                    </Grid>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                        ))
                      }
                    </>
                )
              }
            })}
          </Grid>
          {
              data?.enableLocalServiceAds === "Yes"
              && <>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    marginY: '20px',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #000000'
                  }}>
                    Local Service Ads
                  </Typography>
                </Box>
                <Grid container spacing={0}>
                  {localServiceAdsSchema.map((key: any) => {
                    return (
                        (key !== "schedule" && (data[key] || data[key] === "")) && (
                            <Grid item xs={6} key={key}>
                              <Box mb={2}>
                                <Typography sx={headingStyle}
                                            color="primary"
                                >
                                  {camelCaseToFlat(key)}
                                </Typography>
                                <Typography>{displayNotRequiredField(camelCaseToFlat(key), data[key])}</Typography>
                              </Box>
                            </Grid>
                        )
                    )
                  })}
                </Grid>
                <Grid container spacing={0}>
                  {localServiceAdsSchema.map((key: any) => {
                    if (key.includes('schedule') && data?.[key]) {
                      const formattedKey = camelCaseToFlat(key);
                      let isOpenDayAvailable = false;
                      const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                      const sortedEntries = Object.entries(data?.[key][0])
                      .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]));
                      return (
                          <Grid item xs={6} key={key}>
                            <Box key={key} mb={2}>
                              <Typography sx={headingStyle}
                                          color="primary">{formattedKey}</Typography>
                              {sortedEntries.map(([day, details]) => {
                                const detail = details as DayDetails;
                                if (detail.open) {
                                  isOpenDayAvailable = true;
                                  return (
                                      <div key={day}>
                                        <Typography>
                                          {day}
                                          {' '}
                                          {detail.openTime !== '' ? `-- ${detail.openTime}` : '-'}
                                          {' '}
                                          -
                                          {' '}
                                          {detail.closeTime !== '' ? detail.closeTime : '-'}
                                        </Typography>
                                      </div>
                                  );
                                }
                              })}
                              {!isOpenDayAvailable ? (
                                  <Typography>{`No ${camelCaseToFlat(key)}`}</Typography>
                              ) : ''}
                            </Box>
                          </Grid>
                      );
                    }
                  })}
                </Grid>
              </>
          }
        </Box>
    );
  }

  const renderSemProductData = () => (
      <GridItem sizes={[12, 12]} marginBottom={3}
                sx={{padding: '20px 0 0', borderTop: '1px solid #000000'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {editButton(isEditable, () => handleEditClick(offeringType, product.id))}
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>Solution Strategy</Typography>
        </Box>
        <Box sx={{paddingTop: '10px'}}>
          {renderObjectDetails(productData)}
        </Box>
      </GridItem>
  );

  return (
      <Grid container sx={{borderTop: '1px solid #716F6F'}}>
        {/* Status Tracker */}
        {showTracker && (
            <>
              <Grid item xs={12} sx={{padding: '20px 0px 60px 20px'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  {editButton(isEditable, () => {
                  })}
                  <Typography sx={{fontSize: '16px', fontWeight: '600'}}>Order Status</Typography>
                </Box>
              </Grid>
              <Grid item
                    xs={12}
                    sx={{
                      padding: '0px 0px 60px 20px',
                      borderBottom: '1px solid #000000'
                    }}
              >
                <Box>
                  <OrderTracker
                      key={product?.id}
                      status={status}
                      productName={product?.pdOfferingType}
                      product={product}
                  />
                </Box>
              </Grid>
            </>
        )}
        {/* General Questions */}
        <Grid item xs={12}>
          {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable)}
        </Grid>
        {/* Details */}
        {!product.isRebuild && renderSemProductData()}
        {/* Actions items */}
        {showTracker && ActionItems(false, product?.id, offeringType, productData)}
      </Grid>
  );
}