import AxiosAPI from './common/axios';

const statesUSA = [
  { description: 'Alabama', value: 'AL' },
  { description: 'Alaska', value: 'AK' },
  { description: 'Arizona', value: 'AZ' },
  { description: 'Arkansas', value: 'AR' },
  { description: 'California', value: 'CA' },
  { description: 'Colorado', value: 'CO' },
  { description: 'Connecticut', value: 'CT' },
  { description: 'Delaware', value: 'DE' },
  { description: 'Florida', value: 'FL' },
  { description: 'Georgia', value: 'GA' },
  { description: 'Hawaii', value: 'HI' },
  { description: 'Idaho', value: 'ID' },
  { description: 'Illinois', value: 'IL' },
  { description: 'Indiana', value: 'IN' },
  { description: 'Iowa', value: 'IA' },
  { description: 'Kansas', value: 'KS' },
  { description: 'Kentucky', value: 'KY' },
  { description: 'Louisiana', value: 'LA' },
  { description: 'Maine', value: 'ME' },
  { description: 'Maryland', value: 'MD' },
  { description: 'Massachusetts', value: 'MA' },
  { description: 'Michigan', value: 'MI' },
  { description: 'Minnesota', value: 'MN' },
  { description: 'Mississippi', value: 'MS' },
  { description: 'Missouri', value: 'MO' },
  { description: 'Montana', value: 'MT' },
  { description: 'Nebraska', value: 'NE' },
  { description: 'Nevada', value: 'NV' },
  { description: 'New Hampshire', value: 'NH' },
  { description: 'New Jersey', value: 'NJ' },
  { description: 'New Mexico', value: 'NM' },
  { description: 'New York', value: 'NY' },
  { description: 'North Carolina', value: 'NC' },
  { description: 'North Dakota', value: 'ND' },
  { description: 'Ohio', value: 'OH' },
  { description: 'Oklahoma', value: 'OK' },
  { description: 'Oregon', value: 'OR' },
  { description: 'Pennsylvania', value: 'PA' },
  { description: 'Rhode Island', value: 'RI' },
  { description: 'South Carolina', value: 'SC' },
  { description: 'South Dakota', value: 'SD' },
  { description: 'Tennessee', value: 'TN' },
  { description: 'Texas', value: 'TX' },
  { description: 'Utah', value: 'UT' },
  { description: 'Vermont', value: 'VT' },
  { description: 'Virginia', value: 'VA' },
  { description: 'Washington', value: 'WA' },
  { description: 'West Virginia', value: 'WV' },
  { description: 'Wisconsin', value: 'WI' },
  { description: 'Wyoming', value: 'WY' },
];

const countryItems = [
  { value: 'US', description: 'United States' },
  { value: 'CA', description: 'Canada' },
  { value: 'AU', description: 'Australia' },
  { value: 'NZ', description: 'New Zealand' },
];

const countryNamesConversion = {
  'United States': 'US',
  Canada: 'CA',
  Australia: 'AU',
  'New Zealand': 'NZ',
};

const twoLettersCountryEquivalent = {
  USA: 'US',
  CAN: 'CA',
  AUS: 'AU',
  NZL: 'NZ',
};

export default function useMapboxService() {
  return {
    countryItems,
    countryNamesConversion,
    twoLettersCountryEquivalent,
    statesUSA,
    getAddresses:
      async (query: string, country: string) =>
        (AxiosAPI.get('/address/suggestions', { params: { query, country } })),
    validateAddress:
      async (content: any) => (AxiosAPI.post('/address/validate', content)),
    getServiceAreas:
      async (input: string, country: string) => AxiosAPI.get(
        '/google-map/service-area',
        { params: { input, country } },
      ).then(results => results.data.placeSuggestions.map(item => ({
        label: item.description,
        value: item.placeId,
      }))),
    getServiceAreasList:
        async (input: string, country: string) => AxiosAPI.get(
          '/google-map/list-service-areas',
          { params: { input, country } },
        ).then(results => results.data.placeSuggestions.map(item => ({
          label: item.description,
          value: item.placeId,
        }))),
    getPlaceDetails:
        async (placeId: string) =>
          (AxiosAPI.get('/google-places/details', { params: { placeId } })),
  };
}
