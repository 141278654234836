import {IQDatePicker, IQThemeV2} from '@gannettdigital/shared-react-components';
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import schema from '../GeneralQuestions.schema';
import {adminStartDateOptions, autoRenewOptions} from '../generalQuestionsData';
import {selectActiveOfferingType} from "services/navigationSlice";
import {useSelector} from "react-redux";

const { t } = i18n;

export const DateRequests = (
  adminStartDate,
  setAdminStartDate,
  autoRenew,
  setAutoRenew,
  register,
  errors,
  requestField,
  delayField,
  paymentDate,
  setPaymentDate,
  requestPayDateField,
  delayedRequestedPayDateField,
) => {
  const offeringTypeNotContainsAutoRenewImmediately = [
    'GetCreativeServiceFee',
    'PremiumListing',
    'PremiumListings10PlusPhysicianDirectories',
    'PremiumListings10Plus',
    'NationalSEM',
    'DMSNextCustomerCenterByDash',
    'SMMStandard',
    'SMMCustom',
    'SMMInstagram',
    'SMMPinterest',
    'SEOTopicLocation',
    'CustomSolutionsSpotify',
    'CustomSolutionsTiktok',
    'CustomSolutionsPandora',
    'CustomSolutionsLinkedin',
    'SEOBlogEnhanced',
    'CustomTracking',
    'ChatProduct',
    'LandingPagesManagedServices',
    'SEOCustom',
  ];

  const offeringTypeForAutoRenewOff = [
    'VideoCreativeServicePackage',
    'SpanishChatAddon',
    'ChatHybridAddon',
    'CustomReporting',
    'SMMInfographics',
    'LandingPage'
  ]

  const offeringTypeNotContainsAutoRenewEveryMonth = [
    'ChatPlatinumProduct'
  ]

  const theme = useTheme();
  const offeringType: string = useSelector(selectActiveOfferingType);
  const isOfferingTypeAutoRenewOff: boolean = offeringTypeForAutoRenewOff.includes(offeringType);
  const isDateRequestNotRequired: boolean = offeringType === 'ChatHybridAddon';
  const autoRenewValue: string = isOfferingTypeAutoRenewOff ? 'Auto Renew Off' : autoRenew;
  const disableAutoRenewDisplayCreative: boolean = offeringType.includes('DisplayCreativeServices') || offeringType.includes('DisplayCreativeServiceCustom') || offeringType.includes('TargetedEmail')
  const isOfferingTypeNotContainsAutoRenewImmediately: boolean = offeringTypeNotContainsAutoRenewImmediately.includes(offeringType);
  const isOfferingTypeNotContainsAutoRenewEveryMonth: boolean = offeringTypeNotContainsAutoRenewEveryMonth.includes(offeringType);

  let updatedAutoRenewOptions = autoRenewOptions;

  if (isOfferingTypeNotContainsAutoRenewImmediately) {
    updatedAutoRenewOptions = ['Auto Renew On - Every Month', 'Auto Renew Off'];
  } else if (isOfferingTypeAutoRenewOff) {
    updatedAutoRenewOptions = ['Auto Renew Off'];
  } else if (isOfferingTypeNotContainsAutoRenewEveryMonth) {
    updatedAutoRenewOptions = ['Auto Renew Off', 'Auto Renew On - Immediately'];
  }

  return (
    <>
      <GridItem marginBottom={3}>
        <Typography variant="h5" component="h2">
          {t('pages.generalQuestions.dateRequests')}
        </Typography>
        {!isDateRequestNotRequired && (
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {t('pages.generalQuestions.adminStartDate')}
          </Typography>
          <RadioGroup
            aria-labelledby="admin-start-date"
            name="adminStartDate"
            value={adminStartDate}
            onChange={(e) => setAdminStartDate(e.target.value)}
          >
            {adminStartDateOptions.map(opt => (
              <FormControlLabel
                key={opt}
                {...register('adminStartDate', {
                  validate: (value) => schemaValidate(value, 'adminStartDate', schema),
                })}
                value={opt}
                control={<Radio />}
                label={opt}
              />
            ))}
          </RadioGroup>
        </GridItem>)}
      </GridItem>

      {requestField && (
      <GridItem marginBottom={3} sizes={[8]}>
        <GridItem marginBottom={0}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            marginBottom={1}
            color={errors.requestedStart ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.requestedStart.title')}
          </Typography>
          <IQDatePicker
            id="requestedStart"
            name="requestedStart"
            labelText=""
            fontLabelWeight="bold"
            fullWidth
            theme={theme}
            hasError={!!errors.requestedStart}
          />
          <ErrorText
            hasError={!!errors.requestedStart}
            errorText={errors?.requestedStart?.message}
          />
          <FormHelperText>{t('pages.generalQuestions.requestedStart.support')}</FormHelperText>
        </GridItem>
      </GridItem>
      )}

      {delayField && (
      <>
        <GridItem marginBottom={3} sizes={[8]}>
          <GridItem marginBottom={0}>
            <Typography variant="subtitle2" component="h3" fontSize={16}
              marginBottom={1}
              color={errors.delayedStart ? IQThemeV2.palette.error.main : '#000000'}
            >
              {t('pages.generalQuestions.delayedStart.title')}
            </Typography>
            <IQDatePicker
              id="delayedStart"
              name="delayedStart"
              labelText=""
              fontLabelWeight="bold"
              fullWidth
              theme={theme}
            />
            <ErrorText
              hasError={!!errors.delayedStart}
              errorText={t('pages.generalQuestions.delayedStart.error')}
            />
            <FormHelperText>{t('pages.generalQuestions.delayedStart.support')}</FormHelperText>
          </GridItem>
        </GridItem>

        <GridItem marginBottom={3}>
          <GridItem marginBottom={0}>
            <Typography variant="subtitle2" component="h3" fontSize={16}>
              {t('pages.generalQuestions.paymentDate')}
            </Typography>
            <RadioGroup
              aria-labelledby="payment-date"
              name="paymentDate"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
            >
              {['ASAP', 'Delay'].map(opt => (
                <FormControlLabel
                  key={opt}
                  {...register('paymentDate', {
                    validate: (value) => schemaValidate(value, 'paymentDate', schema),
                  })}
                  value={opt}
                  control={<Radio />}
                  label={opt}
                />
              ))}
            </RadioGroup>
          </GridItem>
        </GridItem>

        {requestPayDateField && (
        <GridItem marginBottom={3} sizes={[8]}>
          <GridItem marginBottom={0}>
            <GridItem marginBottom={1}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                marginBottom={1}
                color={errors.requestedPayDate ? IQThemeV2.palette.error.main : '#000000'}
              >
                {t('pages.generalQuestions.requestedPayDate.title')}
              </Typography>
            </GridItem>
            <IQDatePicker
              id="requestedPayDate"
              name="requestedPayDate"
              labelText=""
              fontLabelWeight="bold"
              fullWidth
              theme={theme}
            />
            <ErrorText
              hasError={!!errors.requestedPayDate}
              errorText={t('pages.generalQuestions.requestedPayDate.error')}
            />
            <FormHelperText>{t('pages.generalQuestions.requestedPayDate.support')}</FormHelperText>
          </GridItem>
        </GridItem>
        )}

        {delayedRequestedPayDateField && (
        <GridItem marginBottom={3} sizes={[8]}>
          <GridItem marginBottom={0}>
            <GridItem marginBottom={1}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                marginBottom={1}
                color={errors.delayedRequestedPayDate ? IQThemeV2.palette.error.main : '#000000'}
              >
                {t('pages.generalQuestions.delayedRequestedPayDate.title')}
              </Typography>
            </GridItem>
            <IQDatePicker
              id="delayedRequestedPayDate"
              name="delayedRequestedPayDate"
              labelText=""
              fontLabelWeight="bold"
              fullWidth
              theme={theme}
            />
            <ErrorText
              hasError={!!errors.delayedRequestedPayDate}
              errorText={t('pages.generalQuestions.delayedRequestedPayDate.error')}
            />
            <FormHelperText>{t('pages.generalQuestions.delayedRequestedPayDate.support')}</FormHelperText>
          </GridItem>
        </GridItem>
        )}
      </>
      )}
      {/*Auto Renew section*/}
      {!disableAutoRenewDisplayCreative &&
          <GridItem marginBottom={1}>
            <Typography variant="subtitle2" component="h3" fontSize={16}>
              {t('pages.generalQuestions.autoRenew')}
            </Typography>
            <RadioGroup
                aria-labelledby="auto-renew"
                name="autoRenew"
                value={autoRenewValue}
                onChange={(e) => setAutoRenew(e.target.value)}
            >
              {updatedAutoRenewOptions.map((opt, index) => {
                if (!((offeringType === 'RLBrandedContent' || offeringType === 'SocialAdsAdEngagementAddOn') && index === 2)) {
                  return (
                      <FormControlLabel
                          key={opt}
                          {...register('autoRenew', {
                            validate: (value) => schemaValidate(value, 'autoRenew', schema),
                          })}
                          value={opt}
                          control={<Radio/>}
                          label={opt}
                      />
                  )
                }
              })}
            </RadioGroup>
          </GridItem>
      }

    </>
  );
};
