import { Children, useMemo } from 'react';
import {
  Box, Button, Divider, Grid, Paper, Stack, Table, TableCell, TableContainer, TableRow, Typography,
  Link as MuiLink,
} from '@mui/material';
import { Product } from 'services/adminSlice';
import { setImageUrl } from 'services/themesSlice';
import { selectAgents } from 'services/salesAgentSlice';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { isCustomProduct } from 'layouts/leftnav/LeftNavMenu';
import { selectOrderItemsWithCidStatus } from 'services/campaignSlice';
import { getCIDStatus } from 'pages/orders/utils';
import { selectOrderDetails } from 'services/myOrdersSlice';
import { getProductName } from 'pages/shopping-cart/ShoppingCart';
import OrderTracker from './OrderTracker';
import { renderStatusPill } from './OrdersTable';

interface Props {
  orderDetails: any
}

export default function OrderDetailsRow({ orderDetails }: Props) {
  const agents = useSelector(selectAgents);
  const orderData = useSelector(selectOrdersContent);
  const campaignStatuses = useSelector(selectOrderItemsWithCidStatus);
  const isCustomQuote = isCustomProduct(orderDetails.map(item => item.orderItem));

  const isSalesforceOrangeOrder = useMemo(() => orderData.orderFlow === OrderFlow.SALES_FORCE, [orderData]);

  const isSalesforceBlueOrder = useMemo(() => orderData.orderFlow === OrderFlow.SALESFORCE_BLUE, [orderData]);

  const isPlProduct = (product: Product) => product.productName === 'PremiumListing'
      || product.productName === 'Premium Listings Healthcare';

  const defaultBorder = '1px solid rgba(224, 224, 224, 1)';

  const order = useSelector(selectOrderDetails);

  const isRetrying = order.some((order:any) => order?.retrying);

  const displayReviewButton = (product) => isPlProduct(product) && (
  <RouterLink
    to={`/order/${product.orderItem.orderId}/review?reviewId=true`}
    style={{ pointerEvents: product.orderStatus?.neonStatus === 'Payment Pending' ? 'none' : 'auto' }}
  >
    <Button
      variant={(product.productName === 'PremiumListing'
                && product.orderStatus?.neonStatus === 'Completed') ? 'outlined' : 'contained'}
      sx={{ width: '150px', paddingY: 1, marginTop: 4 }}
      disabled={product.orderStatus?.neonStatus === 'Payment Pending'}
    >
      <Typography fontWeight="bold" fontSize="17px">
        Review
      </Typography>
    </Button>
  </RouterLink>
  );

  const displayViewProductButton = (product) => (
    <RouterLink to={`/order/${product.orderItem.orderId}/product/${product.orderItem.id}`}>
      <Button
        variant="contained"
        sx={{
          width: '150px', paddingY: 1, marginTop: 4, marginLeft: 1,
        }}
      >
        <Typography fontWeight="bold" fontSize="17px">View Product</Typography>
      </Button>
    </RouterLink>
  );

  const getCid = (product: any) => product?.orderItem?.campaignId;

  const checkTrackerStatus = (product) => {
    const { lastCompletedStep } = product.orderStatus ?? { lastCompletedStep: null };
    if (lastCompletedStep === 'Order Submitted' || lastCompletedStep === null) {
      if (isPlProduct(product)
          || product.orderStatus?.neonStatus?.includes('Payment')
          || product.orderStatus?.neonStatus?.includes('Assignments')) {
        return product.orderStatus?.neonStatus;
      }
    }
    return lastCompletedStep;
  };

  const getProductStatus = (product) => {
    if (product.orderStatus?.neonStatus === 'Incomplete' || product.orderStatus?.wrikeStatus === 'Cancelled'
    || (product.orderItem.campaignId === null && product.orderStatus?.neonStatus === 'Payment Pending')) {
      return 'Off Track';
    }
    return product.orderStatus?.neonStatus;
  };

  const salesAgentsNames = useMemo(() => {
    let agentNames = '';
    Object.keys(agents).forEach((key, index, array) => {
      agentNames += agents[key].salesAgentName;
      if (index < array.length - 1) {
        agentNames += '\n';
      }
    });
    return agentNames;
  }, [agents]);

  const getSfContextText = () => {
    if (isSalesforceOrangeOrder) {
      return 'Opportunity';
    }
    if (isSalesforceBlueOrder) {
      return 'Case';
    }

    return '';
  };

  const getCefUrl = (product) => {
    if (isSalesforceOrangeOrder) {
      return `${process.env.REACT_APP_NEON_FORCE_LIGHTNING_URL}/${product.opportunityId}/view`;
    }
    if (isSalesforceBlueOrder) {
      return `${process.env.REACT_APP_NEON_FORCE_BLUE_LIGHTNING_URL}/${product.opportunityId}/view`;
    }

    return '';
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderLeft: defaultBorder,
        borderRight: defaultBorder,
        borderBottom: defaultBorder,
        background: 'white',
      }}
    >
      {Children.toArray(orderDetails.map(product => (
        <>
          <Divider sx={{ marginBottom: 6 }} />
          <Grid container paddingLeft={12} paddingRight={3}>
            <Grid item xs={9}>
              <Stack direction="row">
                <img
                  style={{ maxWidth: '170px', maxHeight: '170px', marginRight: '30px' }}
                  src={setImageUrl(product.themeId, product.screenshotImage, 'screenshot_image')}
                  alt={product.productName}
                />
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={0} sx={{ fontSize: '16px' }}>
                      <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                        <TableRow>
                          <TableCell colSpan={2} sx={{ border: 'none', whiteSpace: 'nowrap' }}>
                            <Typography fontSize="22px" fontWeight="bold">{getProductName(product)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ width: '140px', border: 'none' }}>Status:</TableCell>
                          <TableCell sx={{ border: 'none' }}>{renderStatusPill(getProductStatus(product))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ width: '140px', border: 'none' }}>Sales Agent:</TableCell>
                          <TableCell sx={{ border: 'none', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>
                            {salesAgentsNames}
                          </TableCell>
                        </TableRow>
                        {!isCustomQuote && (
                        <TableRow>
                          <TableCell sx={{ width: '140px', border: 'none' }}>CID:</TableCell>
                          <TableCell sx={{ border: 'none', fontWeight: 'bold' }}>
                            {getCIDStatus(campaignStatuses, product?.orderItem?.id)}
                          </TableCell>
                        </TableRow>
                        )}
                        {(isSalesforceOrangeOrder || isSalesforceBlueOrder) && (
                          <TableRow>
                            <TableCell sx={{ width: '140px', border: 'none' }}>{`${getSfContextText()}:`}</TableCell>
                            <TableCell sx={{ border: 'none', display: 'flex', alignItems: 'center' }}>
                              <OpenInNewIcon sx={{ marginRight: 1, fontSize: '1rem', color: 'primary.main' }} />
                              <MuiLink href={getCefUrl(product)} underline="always" target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography fontWeight="bold">
                                  {`Go to ${getSfContextText()}`}
                                </Typography>
                              </MuiLink>
                            </TableCell>
                          </TableRow>
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {product.productName !== 'MasterAddon'
                  && (
                  <Typography textAlign="right" fontWeight="bold">
                    Campaign ID :&nbsp;&nbsp;
                    {getCIDStatus(campaignStatuses, product?.orderItem?.id)}
                  </Typography>
                  )}
                <Box display="flex" flexDirection="row">
                  {displayReviewButton(product)}
                  {displayViewProductButton(product)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                marginTop={8}
                marginBottom={7}
              >
                <OrderTracker
                  key={product.orderItem.id}
                  status={checkTrackerStatus(product)}
                  productName={product.productName}
                  product={product}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )))}
    </Box>
  );
}
