/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import {
  ProxyOrProvisioningCustomValidate, ProxyOrProvisioningSchema, ProxyOrProvisioningUISchema,
} from './social-ads-schema/ProxyOrProvisioningSchema';
import { TargetingCustomValidate, TargetingSchema, TargetingUISchema } from './search-engine-marketing/Targeting';
import { SEMAdsCustomValidate, SEMAdsSchema, SEMAdsUISchema } from './search-engine-marketing/Ads';
import { SEMKeywordCustomValidate, SEMKeywordSchema, SEMKeywordUISchema } from './search-engine-marketing/Keyword';
import {
  LocalServiceAdsSchema, LocalServiceAdsSchemaCustomValidate, LocalServiceAdsUISchema
} from "pages/generic-product/details/product-schema/search-engine-marketing/LocalServiceAds";

const { t } = i18n;
const isSem = true;

export const SearchEngineMarketingSchema = (data) => {
  const schema :any = [
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.targeting.title'),
      schema: TargetingSchema(data),
      uiSchema: TargetingUISchema(data),
      customValidate: TargetingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
      schema: ProxyOrProvisioningSchema(data, isSem),
      uiSchema: ProxyOrProvisioningUISchema(data),
      customValidate: ProxyOrProvisioningCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.sem-keywords.title'),
      schema: SEMKeywordSchema(data),
      uiSchema: SEMKeywordUISchema,
      customValidate: SEMKeywordCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.sem-ads.title'),
      schema: SEMAdsSchema(data),
      uiSchema: SEMAdsUISchema,
      customValidate: SEMAdsCustomValidate,
    },
  ]

  if (data.enableLocalServiceAds === "Yes"){
    schema.splice(4, 0,{
      type: 'object',
      title: t('pages.socialAds.sem-ads.localServiceAds'),
      schema: LocalServiceAdsSchema(data),
      uiSchema: LocalServiceAdsUISchema(data),
      customValidate: LocalServiceAdsSchemaCustomValidate,
    })
  }
  return schema
};

const DetailPageUIOrder = [
  'clientHaveALocaliqWebsite',
  'isLocaliqWebsiteLive',
  'localiqWebsiteUrl',
  'temporaryWebsiteUrl',
  'clientFullName',
  'clientEmail',
  'phoneNumber',
  'phoneNumberExtension',
  'clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance',
  'isThisTheBestPersonToGrantUsGoogleAnalyticsAccess',
  'googleAnalyticsContactFullName',
  'googleAnalyticsEmail',
  'googleAnalyticsPhoneNumber',
  'googleAnalyticsPhoneNumberExtension',
];

export const SearchEngineMarketingUISchema = (data) => ({
  'ui:order': [
    ...DetailPageUIOrder,
    ...TargetingUISchema(data)['ui:order'],
    ...ProxyOrProvisioningUISchema(data)['ui:order'],
    ...SEMKeywordUISchema['ui:order'],
    ...SEMAdsUISchema['ui:order'],
    ...LocalServiceAdsUISchema(data)['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SearchEngineMarketingValidate = (formData: any, errors: any, uiSchema: any) => errors;
