import {useEffect, useState, useCallback} from "react";
import {Box, Switch, Typography, Snackbar, Alert, AlertColor} from "@mui/material";
import AxiosAPI from "services/common/axios";

type SnackbarState = {
  open: boolean;
  message: string;
  severity: AlertColor;
};

export const CidCreation = () => {
  const [cidCreation, setCidCreation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "info",
  });

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await AxiosAPI.get("/maintenance/campaigns-toggle");
      setCidCreation( false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch CID Creation status.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSwitch = async () => {
    try {
      const updatedValue = !cidCreation;
      setCidCreation(updatedValue);
      await AxiosAPI.post("/maintenance/campaigns-toggle", {
        campaign_creation_flag: updatedValue,
      });
      setSnackbar({
        open: true,
        message: "CID Creation status updated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating toggle:", error);
      setSnackbar({
        open: true,
        message: "Failed to update CID Creation.",
        severity: "error",
      });
      setCidCreation((prevState) => !prevState);
    }
  };

  const closeSnackbar = () => {
    setSnackbar({open: false, message: "", severity: "info"});
  };

  return (
      <>
        <Box display="flex" alignItems="center" marginRight={2}>
          <Typography>CID Creation:&nbsp;</Typography>
          <Switch
              checked={cidCreation}
              onChange={handleSwitch}
              inputProps={{"aria-label": "controlled"}}
              disabled={true}
          />
        </Box>

        {/* Snackbar for success or error messages */}
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={closeSnackbar}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        >
          <Alert onClose={closeSnackbar}
                 severity={snackbar.severity}
                 sx={{
                   backgroundColor: snackbar.severity === "success" ? "green" : "red",
                   color: "white",
                 }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
  );
};