import * as yup from 'yup';
import { IQButtonLink, IQInputEmail } from '@gannettdigital/shared-react-components';
import { Button, Grid, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import CoModalConfirm from 'components/modals/CoModalConfirm';

interface Props {
  getValues: any
  setValue: any
  schema: any
  watch: any
  control: any
}

export default function SMSLeadEmails({
  getValues, setValue, schema, watch, control,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState({ email: '', index: -1 });
  const [canAddMore, setCanAddMore] = useState(true);

  const { fields: emailsArray, append } = useFieldArray({
    name: 'smsLeadEmailList',
    control,
  });

  const emailListValues = getValues('smsLeadEmailList');
  const emailList = watch(`smsLeadEmailList.${emailListValues.length - 1}`);

  useEffect(() => {
    setCanAddMore(emailListValues[emailListValues.length - 1] !== '');
  }, [emailList]);

  const onEmailAdd = () => append('');

  const onEmailDelete = (index) => {
    const email = getValues('smsLeadEmailList')[index];
    setShowDeleteEmailDialog(true);
    setEmailToDelete({ email, index });
  };

  const confirmEmailDelete = () => {
    const emailsCopy = [...emailListValues];
    emailsCopy.splice(emailToDelete.index, 1);
    setValue('smsLeadEmailList', emailsCopy);
    setShowDeleteEmailDialog(false);
  };

  const cancelEmailDelete = () => {
    setShowDeleteEmailDialog(false);
    setEmailToDelete({ email: '', index: -1 });
  };

  const customValidation = yup.string()
    .email('Please enter a valid email in this format: name@example.com.')
    .optional();

  const emailRow = (email, key) => (
    <Grid item xs={12} display="flex"
      flexDirection="row" key={email.id} mb={3}
    >
      <Grid item xs={key === 0 ? 12 : 11}>
        <IQInputEmail
          name={`smsLeadEmailList.${key}`}
          field={`smsLeadEmailList.${key}`}
          labelText={`${t('pages.ccByDash.solutionStrategy.smsLeadEmailLabel')} ${key + 1}`}
          fontLabelWeight="600"
          theme={theme}
          schema={schema}
          required
          label={`${t('pages.ccByDash.solutionStrategy.smsLeadEmailLabel')} ${key + 1}`}
          fullWidth
          adornmentIcon={<MailOutlineIcon />}
          customValidation={customValidation}
        />
      </Grid>
      { key !== 0 && (
        <Grid item xs={1}
          display="flex"
          alignItems="flex-end"
        >
          <Button onClick={() => onEmailDelete(key)} sx={{ color: theme.palette.primary.main }}>
            <DeleteIcon />
          </Button>
        </Grid>
      ) }
    </Grid>
  );

  return (
    <>
      {emailsArray.map((email, index) => emailRow(email, index)) }
      {emailsArray.length < 3 && (
        <Grid item xs={12}>
          <IQButtonLink startIcon={<AddIcon />} onClick={onEmailAdd} disabled={!canAddMore}>
            Add Lead Notification Email
          </IQButtonLink>
        </Grid>
      )}
      <CoModalConfirm open={showDeleteEmailDialog}
        title="Remove SMS Lead Notification Email"
        description={(
          <Trans
            i18nKey="pages.business.client.removeModal.emailMessage"
            values={{ email: emailToDelete.email ? `"${emailToDelete.email}"` : '' }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText="Remove"
        handleClose={cancelEmailDelete}
        handleConfirm={confirmEmailDelete}
      />
    </>
  );
}
