/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';

export interface RLOfferType {
  offerId: number
  offerName: string
}

export interface ProductType {
  id: number
  offeringType: string
  name: string
  active?: boolean
}

export interface OfferMappingType {
  id: number
  name: string
  neonProductName: string
  offerId: number
}

export interface OfferMappingStateType {
  offers: StatusType<RLOfferType[]>
  products: StatusType<ProductType[]>
  mappings: StatusType<OfferMappingType[]>
}

const initialState: OfferMappingStateType = {

  offers: {
    status: 'idle',
    message: '',
    content: [],
  },
  products: {
    status: 'idle',
    message: '',
    content: [],
  },
  mappings: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getAllRLOffers = createAsyncThunkCatchErrors(
  'allRLOffers/get',
  async ({ countryId }) => {
    const response = await AxiosAPI.get('/product/types/offers', {
      params: {
        countryId,
      },
    });
    return response.data;
  },
);

export const getAllProductTypes = createAsyncThunkCatchErrors(
  'allProductTypes/get',
  async () => {
    const response = await AxiosAPI.get('/product/types/list');
    return response.data;
  },
);

export const getAllOfferMappings = createAsyncThunkCatchErrors(
  'allOfferMappings/get',
  async ({ countryId }) => {
    const response = await AxiosAPI.get(`/product/types/mappings/${countryId}`);
    return response.data;
  },
);

export const createOrUpdateProductToOfferMapping = createAsyncThunkCatchErrors(
  'productToOfferMap/create',
  async (resource) => {
    const response = await AxiosAPI.post('/product/types/mappings', resource);
    const { data } = response;
    return data;
  },
);

export const deleteProductToOfferMapping = createAsyncThunkCatchErrors(
  'productToOfferMap/delete',
  async (id) => {
    const response = await AxiosAPI.delete(`/product/types/mapping/${id}`);
    const { data } = response;
    return data;
  },
);

export const offerMappingSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getAllRLOffers, 'offers')
      .addCase(getAllProductTypes, 'products')
      .addCase(getAllOfferMappings, 'mappings');

    builder.addCase(createOrUpdateProductToOfferMapping.fulfilled, (state, { payload }) => {
      const id = state.mappings.content.length + 100;
      const itemsToCopy = [...state.mappings.content];
      itemsToCopy.push({ id, ...payload });
      state.mappings.content = itemsToCopy;
    });
  },
);

export const getOfferIdByOfferName = async (offerName: string,countryCode: string) => {
  try {
    const response = await AxiosAPI.get('/product/types/offeringId?offerName=' + offerName+'&countryCode='+countryCode);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const selectAllRLOffers = (state: RootState) => state.offersMap.offers.content;
export const selectAllProductTypes = (state: RootState) => state.offersMap.products.content;
export const selectAllOfferMappings = (state: RootState) => state.offersMap.mappings.content;

export default offerMappingSlice;
