/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './redux/store-schedule';

export interface dmaTargetingDetailsState {
  dmaTargetingDetails: any;
  dmaTargetingDetailsFlag: boolean;
}

const initialState: dmaTargetingDetailsState = {
  dmaTargetingDetails: [],
  dmaTargetingDetailsFlag: false,
};

const saveDmaTargetingDetailsSlice = createSlice({
  name: 'dmaTargetingDetails',
  initialState,
  reducers: {
    saveDmaTargetingDetails(state, action: PayloadAction<any>) {
      state.dmaTargetingDetails = action.payload;
      state.dmaTargetingDetailsFlag = action.payload;
    },
  },
});

export const {saveDmaTargetingDetails} = saveDmaTargetingDetailsSlice.actions;

export const selectDmaTargetingDetails = (state: RootState) => state.dmaTargetingDetails.dmaTargetingDetails;

export default saveDmaTargetingDetailsSlice.reducer;
