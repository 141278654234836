import * as yup from 'yup';
import i18n from "i18n/i18n";
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

// Regular expression to match Facebook URLs
const facebookUrlRegex = /^(https?:\/\/)?(www\.)?(facebook\.com\/|fb\.com\/)[a-zA-Z0-9(\.\?)?]/;

const schema = {
  selectCampaignGoalFocus: yup.string().required(t('pages.xmo.tactics.socialSmartOptimization.selectCampaignGoalFocusErrorMsg')),
  landingPageUrl: yup.string()
  .notRequired()
  .test(
      'validate-url',
      t('pages.xmo.tactics.socialSmartOptimization.landingPageUrlErrorMsg'),
      function (value) {
        if (!value || value.trim().length === 0) {
          return true;
        }
        return urlValidationRegex.test(value);
      }
  ),
  landingPageIsNotLive: yup.string().notRequired(),
  facebookPageUrl: yup.string()
  .notRequired()
  .test(
      'validate-facebook-url',
      t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlErrorMsg'),
      function (value) {
        if (!value || value.trim().length === 0) {
          return true;
        }
        return facebookUrlRegex.test(value);
      }
  ),
  facebookPageIsNotLive: yup.string().notRequired(),
  allowEmojis: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
