import React, {
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import {
  TextField,
  Grid,
  Chip,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  saveCityTargetingDetails,
  selectCityTargetingDetails,
} from "services/cityTargetingdetailsSlice";
import { RootState } from "services/redux/store-schedule";
import AxiosAPI from "services/common/axios";
import { t } from "i18next";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorIcon from "@mui/icons-material/Error";
import { useParams } from "react-router";
import { getOrderBusinessByOrderId, selectOrderBusiness } from "services/businessSlice";
import { getCountryCodeFromGmaid } from "shared/common";

export default function CityTargetingDetails(props: any) {
  const { data, stepper, cityDetails, setCityDetails, nonSchema} = props;
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const savedTargetingDetails = useSelector((state: RootState) =>
      selectCityTargetingDetails(state)
  );
  const [originalAddressData, setOriginalAddressData] = useState<any[]>([]);
  const [addressData, setAddressData] = useState<any[]>([]);
  const [items, setItems] = useState<any>(
      cityDetails|| savedTargetingDetails.cityTargetingDetails || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [itemExistErrorMessage, setItemExistErrorMessage] = useState<boolean>(false);
  const debounceTimer = useRef<any>(null);
  const [inputValue, setInputValue] = useState<any>("");

  // For nonSchema product
  useEffect(() => {
    if (items && nonSchema) {
      setItems(cityDetails);
    }
  }, [cityDetails]);

  useEffect(() => {
    if (nonSchema) setCityDetails(items)
  }, [items, nonSchema]);

  // For schema product
  useEffect(() => {
    if (data?.cityTargetingDetails) {
      setItems(data.cityTargetingDetails);
    }
    if (stepper) props.onChange('')
  }, [data]);

  useEffect(() => {
    if (items && !nonSchema) {
      dispatch(
          saveCityTargetingDetails({
            cityTargetingDetails: items,
            cityTargetingDetailsFlag: true,
          })
      );
    }
  }, [items, dispatch]);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const fetchCityData = useCallback(
      async (query: string) => {
        if (!query.trim()) {
          setAddressData([]);
          setErrorMessage(false);
          return;
        }
        setIsLoading(true);
        setErrorMessage(false);
        try {
          const countryCode = getCountryCodeFromGmaid(content?.gmaid);
          if (!query.includes(',')) {
            const response: any = await AxiosAPI.get(
                `/public/city/fetchGeoTargetIdForCity?country=${countryCode}&city=${query}`
            );
            if (response.status === 200) {
              const data = response.data;
              const filteredData = data.filter((address: any) =>
                  !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
              );
              setAddressData(filteredData);
              setOriginalAddressData(filteredData);
            }
            setItemExistErrorMessage(false);
          }
        } catch (error) {
          setItemExistErrorMessage(false);
          setErrorMessage(true);
        } finally {
          setIsLoading(false);
        }
      },
      [content, items]
  );

  const handleInputChange = (event: any, value: string, type: any) => {
    if (type === "clear") {
      setInputValue("");
      setAddressData([]);
    }
    if (type === "input") {
      setInputValue(value);
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        fetchCityData(value);
      }, 500);
    }
  };

  const handleCitySelect = (selectedCity: any) => {
    if (!selectedCity) {
      return;
    }

    if (items.some((item: any) => item?.geoTargetId === selectedCity?.geoTargetId)) {
      setItemExistErrorMessage(true);
      return;
    }

    setItems((prevItems) => [...prevItems, selectedCity]);
    setItemExistErrorMessage(false);
  };

  useEffect(() => {
    const filteredData = originalAddressData.filter((address: any) =>
        !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
    );
    setAddressData(filteredData);
  }, [items, originalAddressData]);

  const handleDelete = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  return (
      <Grid container direction="column" spacing={2} mt={1}>
        <Grid item>
          <Typography
              variant="subtitle1"
              sx={{fontSize: "16px", fontWeight: "600"}}
          >
            {t("pages.localServiceAds.targetingType.cityTargetingDetailsLabel")}
          </Typography>
        </Grid>
        <Grid item>
          <Autocomplete
              freeSolo
              key={addressData.length}
              inputValue={inputValue}
              options={addressData}
              value={null}
              getOptionLabel={(option) =>
                  addressData.length > 0 ? option?.city + ", " + option?.state : ""
              }
              onInputChange={handleInputChange}
              onChange={(event: any, value: any) => {
                if (value && typeof value !== "string") {
                  handleCitySelect(value);
                }
              }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      placeholder="Search Cities"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                              {isLoading ? <CircularProgress size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                        ),
                      }}
                  />
              )}
          />
          {
              errorMessage &&
              <Box mt={1} sx={{display: 'flex', alignItems: 'center', color: '#C20F1E'}}>
                <ErrorIcon
                    sx={{
                      fontSize: '16px',
                      marginRight: '5px',
                    }}
                />
                <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                    }}>
                  {t('pages.localServiceAds.targetingType.cityTargetingDetailsErrorMessage')}
                </Typography>
              </Box>
          }
          {
              itemExistErrorMessage &&
              <Box mt={1} sx={{display: 'flex', alignItems: 'center', color: '#C20F1E'}}>
                <ErrorIcon
                    sx={{
                      fontSize: '16px',
                      marginRight: '5px',
                    }}
                />
                <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                    }}>
                  {t('pages.localServiceAds.targetingType.cityTargetingDetailsAlreadyAddedErrorMessage')}
                </Typography>
              </Box>
          }
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
            {items.map((item, index) => (
                <Chip
                    key={index}
                    label={`${item.city}, ${item.state}`}
                    onDelete={() => handleDelete(index)}
                    deleteIcon={
                      <CloseIcon style={{color: "#fff", fontSize: "18px"}}/>
                    }
                    sx={{
                      backgroundColor: "#0053F0",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                />
            ))}
            {items.length >= 2 && <Typography
                onClick={() => {
                  setItems([])
                }}
                sx={{
                  color: '#0053F0',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
            >
              {t("pages.localServiceAds.targetingType.clearAll")}
            </Typography>}
          </Box>
        </Grid>
        {nonSchema &&
            <Grid item>
              <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    color: "#716F6F",
                    marginTop: "-15px"
                  }}>
                {t('pages.localServiceAds.targetingType.cityTargetingDetailsDescriptionForCityState')}
              </Typography>
            </Grid>
        }
      </Grid>
  );
}