/* eslint-disable no-param-reassign */
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQDatePicker,
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import schemaValidate from 'components/schemaValidate';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup, TextField,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router';
import ErrorIcon from '@mui/icons-material/Error';
import { Urls } from 'navigation/Urls';
import useMapboxService from 'services/MapboxService';
import { TargetedDisplayFormProps, PageComponentProps } from './TargetedDisplay.interface';
import { Tactics } from './genericTactics/Tactics';
import {
  countryList,
  campaignGoalsList,
  targetingTypeList,
  yesNoList,
  flightedCampaignList,
} from './TargetedDisplay.service';
import schema from './TargetedDisplay.schema';
import { normalizeNewLines } from "shared/constants";
import { targetingTypeRadiusDistanceDropdownItems } from "pages/xmo/solutionStrategy/targeting/XmoTargetingService";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveOfferingType } from "services/navigationSlice";
import CityTargetingDetails from "pages/generic-product/details/custom-fields/CityTargetingDetails";
import DmaTargetingDetails from "pages/generic-product/details/custom-fields/DmaTargetingDetails";
import {getOrderBusinessByOrderId, selectOrderBusiness} from "services/businessSlice";
import { getCountryCodeFromGmaid } from "shared/common";

export default function TargetedDisplayForm(props: PageComponentProps) {
  const { page, orderId } = useParams();
  const dispatch = useDispatch();
  const { content } = useSelector(selectOrderBusiness);
  const { product, onSubmit, onBack } = props;
  const { t } = useTranslation();
  const isTargetedFieldsRequired = page === Urls.TargetedDisplay;
  const pageType = {
    xmoUpgrade: page === Urls.XmoTargetedDisplay,
    xmo: page === Urls.XmoTargetedDisplay,
  };
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const [startEndDateError, setStartEndDateError] = useState<boolean>(false);
  const [requestDateError, setRequestDateError] = useState<boolean>(false);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [radiusAddressError, setRadiusAddressError] = useState<boolean>(false);
  const [cityDetails, setCityDetails] = useState<any>([]);
  const [dmaDetails, setDmaDetails] = useState<any>([]);
  const deBouncerRef = useRef<any>(null);
  const offeringType = useSelector(selectActiveOfferingType);

  const methods = useForm<TargetedDisplayFormProps>({
    mode: 'all',
    defaultValues: {
      country: product?.country || '',
      cannabisCampaign: product?.cannabisCampaign || '',
      targetingType: product?.targetingType || '',
      goalValue:  product?.goalValue || '',
      goalComment: product?.goalComment || '',
      countryToTarget: product?.countryToTarget || '',
      statesToTarget: product?.statesToTarget || '',
      metrosToTarget: product?.metrosToTarget || '',
      radiusAddress: product?.radiusAddress || '',
      radiusSize: product?.radiusSize || '',
      campaignRequestedStartDate: product?.campaignRequestedStartDate || '',
      flightedCampaign: product?.flightedCampaign || '',
      flightStartDate: product?.flightStartDate || '',
      flightEndDate: product?.flightEndDate || '',
      campaignFlightDetails: normalizeNewLines(product?.campaignFlightDetails) || '',
      campaignGoals: product?.campaignGoals || [],
      clientSuccessManagerEmail: product?.clientSuccessManagerEmail || '',
      rootWebsiteHomePageUrl: product?.rootWebsiteHomePageUrl || '',
      highPriorityWebPageUrls: normalizeNewLines(product?.highPriorityWebPageUrls) || '',
      formSubmissionWebSiteUrls: normalizeNewLines(product?.formSubmissionWebSiteUrls) || '',
      additionalNotes: normalizeNewLines(product?.additionalNotes) || '',
      tactics: product?.tactics || [],
    },
  });

  useEffect(() => {
    if (product?.cityTargetingDetails) setCityDetails(product?.cityTargetingDetails)
    if (product?.dmaTargetingDetails) setDmaDetails(product?.dmaTargetingDetails)
  }, [product]);

  const updateTactics = (value) => {
    setValue('tactics', value);
  };

  const handleFormSubmit = (data: TargetedDisplayFormProps) => {
    if (targetingTypeSelected.city) {
      delete data.countryToTarget;
      delete data.statesToTarget;
      delete data.metrosToTarget;
      delete data.radiusAddress;
      delete data.radiusSize;
    } else if (targetingTypeSelected.country) {
      delete data.statesToTarget;
      delete data.metrosToTarget;
      delete data.radiusAddress;
      delete data.radiusSize;
    } else if (targetingTypeSelected.metro) {
      delete data.countryToTarget;
      delete data.statesToTarget;
      delete data.radiusAddress;
      delete data.radiusSize;
    } else if (targetingTypeSelected.state) {
      delete data.countryToTarget;
      delete data.metrosToTarget;
      delete data.radiusAddress;
      delete data.radiusSize;
    } else if (targetingTypeSelected.radius) {
      delete data.countryToTarget;
      delete data.metrosToTarget;
      delete data.statesToTarget;
    } else {
      delete data.countryToTarget;
      delete data.statesToTarget;
      delete data.metrosToTarget;
      delete data.radiusAddress;
      delete data.radiusSize;
    }
    if (!isTargetedFieldsRequired) {
      delete data.campaignRequestedStartDate;
      delete data.flightedCampaign;
      delete data.flightStartDate;
      delete data.flightEndDate;
      delete data.campaignFlightDetails;
      delete data.crossMediaOptimization;
    }

    data.radiusAddress = (!data.radiusAddress) ? selectedAddress?.label : '';

    const pageFormData = {
      targetedDisplay: [
        {
          ...data,
          cityTargetingDetails: targetingType === 'City'? cityDetails : [],
          dmaTargetingDetails: targetingType === 'Metro (DMA)'? dmaDetails : [],
        },
      ],
    };
    onSubmit(pageFormData);
  };

  const {
    handleSubmit,
    register,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
    watch,
    setValue,
    control,
  } = methods;

  const country = watch('country');
  const tactics = watch('tactics');
  const targetingType = watch('targetingType');
  const campaignRequestDateData = watch('campaignRequestedStartDate');
  const isFlightedCampaignEqualsYes = (watch('flightedCampaign')).includes('Yes');
  const flightedStartDate = watch('flightStartDate');
  const flightedEndDate = watch('flightEndDate');

  const [places, setPlaces] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(product?.radiusAddress);
  const { getServiceAreas } = useMapboxService();

  useEffect(() => {
    if (campaignRequestDateData !== '' && new Date(campaignRequestDateData).getTime() < currentDate) {
      setRequestDateError(true);
    } else {
      setRequestDateError(false);
    }
  }, [campaignRequestDateData]);

  useEffect(() => {
    if (targetingType === 'Radius' && (selectedAddress === '' || selectedAddress === undefined)) {
      setRadiusAddressError(true);
    } else {
      setRadiusAddressError(false);
    }
  }, [targetingType, selectedAddress]);

  useEffect(() => {
    if (flightedStartDate !== '' && new Date(flightedStartDate).getTime() < currentDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    if (flightedEndDate !== '' && new Date(flightedEndDate).getTime() < currentDate) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }

    if (flightedStartDate !== '' && flightedEndDate !== '') {
      if (new Date(flightedStartDate).getTime() > new Date(flightedEndDate).getTime()) {
        setStartEndDateError(true);
      } else {
        setStartEndDateError(false);
      }
    }
  }, [flightedStartDate, flightedEndDate]);

  const targetingTypeSelected = useMemo(() => {
    const selected = {
      country:
          targetingType
          === t(
            'pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.country',
          ),
      state:
          targetingType
          === t('pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.state'),
      metro:
          targetingType
          === t('pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.metro'),
      city:
          targetingType
          === t('pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.city'),
      zip:
          targetingType
          === t('pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.zip'),
      radius:
          targetingType
          === t('pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.radius'),
    };
    return selected;
  }, [targetingType]);

  const isUSACountrySelected = useMemo(() => (
    country === t('pages.xmo.targetedDisplay.campaignInfo.countryOptions.USA')
  ), [country]);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  const getSuggestionsDetails = async (e) => {
    try {
      const countryCode = getCountryCodeFromGmaid(content?.gmaid);
      const address = await getServiceAreas(e, countryCode);
      setPlaces(address);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (e: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestionsDetails(e);
    }, 1500);
  };

  return (
      <DefaultPageLayout
          disableContinue={!isValid || radiusAddressError}
          onBack={onBack}
          header={t('pages.xmo.targetedDisplay.title')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography variant="h6">
                    {t('pages.xmo.targetedDisplay.campaignInfo.title')}
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Grid item>
                    <Typography variant="subtitle2" component="h3" fontSize={16}>
                      {t('pages.xmo.targetedDisplay.campaignInfo.countryLabel')}
                    </Typography>
                    <RadioGroup
                        id="country"
                        name="country"
                        aria-labelledby="topic-location"
                        row
                        defaultValue={product?.country || ''}
                    >
                      {countryList.map((option) => (
                          <FormControlLabel
                              key={option.value}
                              control={<Radio value={option.value}/>}
                              label={option.description}
                              {...register('country', {
                                validate: (value) =>
                                    schemaValidate(value, 'country', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Grid>
                </GridItem>
                {isUSACountrySelected && (
                    <GridItem sizes={[12]}>
                      <Grid item>
                        <Typography
                            variant="subtitle2"
                            component="h3"
                            fontSize={16}
                        >
                          {t(
                              'pages.xmo.targetedDisplay.campaignInfo.cannabisCampaignLabel',
                          )}
                        </Typography>
                        <RadioGroup
                            id="cannabisCampaign"
                            name="cannabisCampaign"
                            aria-labelledby="topic-location"
                            row
                            defaultValue={product?.cannabisCampaign || ''}
                        >
                          {yesNoList.map((option) => (
                              <FormControlLabel
                                  key={option.value}
                                  control={<Radio value={option.value}/>}
                                  label={option.description}
                                  {...register('cannabisCampaign', {
                                    validate: (value) =>
                                        schemaValidate(value, 'cannabisCampaign', schema),
                                  })}
                              />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </GridItem>
                )}
                {
                    ["PromotionsMediaMix", "TargetedDisplay"].includes(offeringType) &&
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="goalValue"
                            name="goalValue"
                            labelText={t(
                                'pages.xmo.targetedDisplay.campaignInfo.goalValue',
                            )}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="goalComment"
                            name="goalComment"
                            labelText={t(
                                'pages.xmo.targetedDisplay.campaignInfo.goalComment',
                            )}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>
                    </>
                }
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="targetingType"
                      name="targetingType"
                      labelText={t(
                          'pages.xmo.targetedDisplay.campaignInfo.targetingTypeLabel',
                      )}
                      defaultValue={product?.targetingType || ''}
                      items={targetingTypeList}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      showError
                  />
                  <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                    <FormHelperText variant="filled">
                      {t(
                          'pages.xmo.targetedDisplay.campaignInfo.targetingTypeDescription',
                      )}
                    </FormHelperText>
                  </Grid>
                </GridItem>
                {targetingTypeSelected.country && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="countryToTarget"
                          name="countryToTarget"
                          labelText={t(
                              'pages.xmo.targetedDisplay.campaignInfo.countryToTargetLabel',
                          )}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                      />
                    </GridItem>
                )}
                {targetingTypeSelected.state && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="statesToTarget"
                          name="statesToTarget"
                          labelText={t(
                              'pages.xmo.targetedDisplay.campaignInfo.statesToTargetLabel',
                          )}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                      />
                      <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                        <FormHelperText variant="filled">
                          {t(
                              'pages.xmo.targetedDisplay.campaignInfo.separateByCommasDescription',
                          )}
                        </FormHelperText>
                      </Grid>
                    </GridItem>
                )}
                {targetingTypeSelected.metro && (
                    <GridItem sizes={[12]} marginTop={-3}>
                      <DmaTargetingDetails dmaDetails={dmaDetails}
                                           setDmaDetails={setDmaDetails}
                                           nonSchema={true}
                      />
                    </GridItem>
                )}
                {targetingTypeSelected.city && (
                    <GridItem sizes={[12]} marginTop={-3}>
                      <CityTargetingDetails cityDetails={cityDetails}
                                            setCityDetails={setCityDetails}
                                            nonSchema={true}
                      />
                    </GridItem>
                )}
                {targetingTypeSelected.radius && (
                    <>
                      <>
                        <GridItem sizes={[12]} marginBottom={2}>
                          <Typography
                              fontWeight={600}
                              sx={{
                                color: IQThemeV2.palette.text.primary,
                              }}
                          >
                            {t('pages.xmo.targetedDisplay.campaignInfo.radiusAddressLabel')}
                          </Typography>
                        </GridItem>
                        <GridItem sizes={[12]} marginBottom={2}>
                          <Autocomplete
                              value={selectedAddress}
                              disablePortal
                              defaultValue={selectedAddress}
                              id="radiusAddress"
                              options={places}
                              sx={{
                                width: '100%',
                                border: '1px solid #292928',
                                '& .MuiOutlinedInput-notchedOutline': {border: 0},
                              }}
                              onInputChange={(event, value) => {
                                handleInputChange(value);
                              }}
                              onChange={(event, value) => {
                                setSelectedAddress(value);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                        </GridItem>
                      </>
                      <Grid mt={-1.5} fontSize="0.9rem" fontWeight={600}>
                        <FormHelperText variant="filled">
                          {t(
                              'pages.xmo.targetedDisplay.campaignInfo.radiusAddressDescription',
                          )}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} mt={3}
                            sx={{marginBottom: '8px'}}
                      >
                        <IQFormSelect
                            id="radiusSize"
                            name="radiusSize"
                            labelText={t('pages.xmo.targetedDisplay.campaignInfo.radiusSizeLabel')}
                            items={targetingTypeRadiusDistanceDropdownItems()}
                            defaultValue=""
                            theme={IQTheme}
                            fontLabelWeight="600"
                            required
                            fullWidth
                            showError
                            schema={schema}
                        />
                      </Grid>
                      <Grid mt={-1.5} mb={3} fontSize="0.9rem"
                            fontWeight={600}
                      >
                        <FormHelperText variant="filled">
                          {t(
                              'pages.xmo.targetedDisplay.campaignInfo.radiusSizeDescription',
                          )}
                        </FormHelperText>
                      </Grid>
                    </>
                )}
                {isTargetedFieldsRequired && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQDatePicker fullWidth
                                      fontLabelWeight="600"
                                      id="campaignRequestedStartDate"
                                      name="campaignRequestedStartDate"
                                      labelText={t('pages.targetedDisplay.solutionStrategy.campaignRequestedStartDate.title')}
                                      theme={IQTheme}
                                      showError
                                      required
                        />
                        <Grid>
                          <Typography sx={{color: '#C20F1E', fontSize: '14px'}} variant="subtitle1">
                            {requestDateError && (
                                <ErrorIcon sx={{
                                  fontSize: '16px',
                                  marginTop: '6px',
                                }}
                                />
                            )}
                            {' '}
                            {requestDateError ? t('pages.targetedDisplay.solutionStrategy.campaignRequestedStartDate.error') : ''}
                          </Typography>
                        </Grid>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Grid item mt={-1.5}>
                          <Typography variant="subtitle2" component="h3" fontSize={16}>
                            {t('pages.targetedDisplay.solutionStrategy.flightedCampaignLabel')}
                          </Typography>
                          <RadioGroup
                              id="flightedCampaign"
                              name="flightedCampaign"
                              aria-labelledby="topic-location"
                              defaultValue={product?.flightedCampaign || undefined}
                          >
                            {flightedCampaignList.map(option => (
                                <FormControlLabel
                                    key={option.value}
                                    control={<Radio value={option.value}/>}
                                    label={option.description}
                                    {...register('flightedCampaign', {
                                      validate: (value) => schemaValidate(value, 'flightedCampaign', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Grid>
                      </GridItem>
                      {isFlightedCampaignEqualsYes
                          && (
                              <>
                                <GridItem sizes={[6, 6]} columnSpacing={2}>
                                  <Controller
                                      name="flightStartDate"
                                      control={methods.control}
                                      render={({field}) => (
                                          <>
                                            <IQDatePicker
                                                id="flightStartDate"
                                                {...field}
                                                schema={schema}
                                                theme={IQTheme}
                                                fontLabelWeight="600"
                                                labelText={t('pages.targetedDisplay.solutionStrategy.flightStartDateLabel')}
                                                required
                                                fullWidth
                                                showError
                                            />
                                            <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                                        variant="subtitle1"
                                            >
                                              {startDateError && (
                                                  <ErrorIcon sx={{
                                                    fontSize: '16px',
                                                    marginTop: '6px',
                                                  }}
                                                  />
                                              )}
                                              {' '}
                                              {startDateError ? t('pages.targetedDisplay.solutionStrategy.flightDateError') : ''}
                                            </Typography>
                                          </>
                                      )}
                                  />
                                  <Controller
                                      name="flightEndDate"
                                      control={methods.control}
                                      render={({field}) => (
                                          <>
                                            <IQDatePicker
                                                id="flightEndDate"
                                                {...field}
                                                schema={schema}
                                                theme={IQTheme}
                                                fontLabelWeight="600"
                                                labelText={t('pages.targetedDisplay.solutionStrategy.flightEndDateLabel')}
                                                required
                                                fullWidth
                                            />
                                            <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                                        variant="subtitle1"
                                            >
                                              {endDateError && (
                                                  <ErrorIcon sx={{
                                                    fontSize: '16px',
                                                    marginTop: '6px',
                                                  }}
                                                  />
                                              )}
                                              {' '}
                                              {endDateError ? t('pages.targetedDisplay.solutionStrategy.flightDateError') : ''}
                                            </Typography>
                                          </>
                                      )}
                                  />
                                </GridItem>
                                <Grid item xs={12} marginTop={-2}>
                                  <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                              variant="subtitle1"
                                  >
                                    {startEndDateError && (
                                        <ErrorIcon sx={{
                                          fontSize: '16px',
                                          marginTop: '6px',
                                        }}
                                        />
                                    )}
                                    {' '}
                                    {startEndDateError ? t('pages.targetedDisplay.solutionStrategy.endDateAfterError') : ''}
                                  </Typography>
                                </Grid>
                                <GridItem>
                                  <Grid item xs={12} sx={{marginBottom: '8px'}}>
                                    <IQFormTextArea
                                        id="campaignFlightDetails"
                                        labelText={t('pages.targetedDisplay.solutionStrategy.campaignFlightDetailsLabel')}
                                        name="campaignFlightDetails"
                                        rowCount={2}
                                        fullWidth
                                        fontLabelWeight="600"
                                    />
                                    <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                                      <FormHelperText
                                          variant="filled"
                                      >
                                        {t('pages.targetedDisplay.solutionStrategy.campaignFlightDetailsDescription')}
                                      </FormHelperText>
                                    </Grid>
                                  </Grid>
                                </GridItem>
                              </>
                          )}
                    </>
                )}
                <GridItem inlineItems sizes={[12]}>
                  <Grid>
                    <Grid>
                      <Typography
                          variant="subtitle2" component="h3"
                          fontSize={16}
                      >
                        {t('pages.targetedDisplay.solutionStrategy.campaignGoalsLabel')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      {campaignGoalsList.map((option) => (
                          <Grid item xs={12} sm={6}
                                key={option.value}
                          >
                            <FormControlLabel
                                control={(
                                    <Controller
                                        name="campaignGoals"
                                        control={control}
                                        {...register('campaignGoals', {
                                          validate: (value) => schemaValidate(value, 'campaignGoals', schema),
                                        })}
                                        render={({field}) => (
                                            <Checkbox
                                                {...field}
                                                value={option.value}
                                                checked={field.value.includes(option.value)}
                                                onChange={() => {
                                                  const newValue = field.value.includes(option.value)
                                                      ? field.value.filter(e => e !== option.value)
                                                      : [...field.value, option.value];
                                                  field.onChange(newValue);
                                                }}
                                            />
                                        )}
                                    />
                                )}
                                label={option.description}
                            />
                          </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </GridItem>
                {isTargetedFieldsRequired
                    && (
                        <GridItem sizes={[12]}>
                          <Grid item mt={3}>
                            <Typography variant="subtitle2" component="h3" fontSize={16}>
                              {t('pages.targetedDisplay.solutionStrategy.crossMediaOptimizationLabel')}
                            </Typography>
                            <RadioGroup
                                id="crossMediaOptimization"
                                name="crossMediaOptimization"
                                aria-labelledby="topic-location"
                                row
                                defaultValue={product?.crossMediaOptimization || undefined}
                            >
                              {yesNoList.map(option => (
                                  <FormControlLabel
                                      key={option.value}
                                      control={<Radio value={option.value}/>}
                                      label={option.description}
                                      {...register('crossMediaOptimization', {
                                        validate: (value) => schemaValidate(value, 'crossMediaOptimization', schema),
                                      })}
                                  />
                              ))}
                            </RadioGroup>
                          </Grid>
                        </GridItem>
                    )}
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="clientSuccessManagerEmail"
                      name="clientSuccessManagerEmail"
                      labelText={t(
                          'pages.xmo.targetedDisplay.campaignInfo.clientSuccessManagerEmailLabel',
                      )}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      adornmentIcon={<EmailIcon/>}
                  />
                  <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                    <FormHelperText variant="filled">
                      {t(
                          'pages.xmo.targetedDisplay.campaignInfo.clientSuccessManagerEmailDescription',
                      )}
                    </FormHelperText>
                  </Grid>
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="rootWebsiteHomePageUrl"
                      name="rootWebsiteHomePageUrl"
                      labelText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.rootWebsiteHomePageUrlLabel',
                      )}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      tooltipText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.rootWebsiteHomePageUrlTooltip',
                      )}
                      tooltipPlacement="right"
                      tooltipPaddingBottom={20}
                      adornmentIcon={<InsertLinkIcon/>}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="highPriorityWebPageUrls"
                      labelText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.highPriorityWebPageUrlsLabel',
                      )}
                      name="highPriorityWebPageUrls"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      tooltipPlacement="right"
                      tooltipText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.highPriorityWebPageUrlsTooltip',
                      )}
                      tooltipPaddingBottom={20}
                      required
                      showError
                  />
                  <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                    <FormHelperText variant="filled">
                      {t(
                          'pages.xmo.targetedDisplay.websiteInfo.highPriorityWebPageUrlsDescription',
                      )}
                    </FormHelperText>
                  </Grid>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="formSubmissionWebSiteUrls"
                      labelText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.formSubmissionWebSiteUrlsLabel',
                      )}
                      name="formSubmissionWebSiteUrls"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      tooltipPlacement="right"
                      tooltipText={t(
                          'pages.xmo.targetedDisplay.websiteInfo.formSubmissionWebSiteUrlsTooltip',
                      )}
                      tooltipPaddingBottom={20}
                  />
                  <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                    <FormHelperText variant="filled">
                      {t(
                          'pages.xmo.targetedDisplay.websiteInfo.formSubmissionWebSiteUrlDescription',
                      )}
                    </FormHelperText>
                  </Grid>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Tactics tactics={tactics} onChange={updateTactics}/>
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="additionalNotes"
                      labelText={t(
                          'pages.xmo.targetedDisplay.additionalNotesLabel',
                      )}
                      name="additionalNotes"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                  />
                  <Grid mt={-3.5} fontSize="0.9rem" fontWeight={600}>
                    <FormHelperText variant="filled">
                      {t('pages.xmo.targetedDisplay.additionalNotesDescription')}
                    </FormHelperText>
                  </Grid>
                </GridItem>
              </GridContainer>
            </Box>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
