import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;
const urlValidationParameter = ['https://www.facebook.com'];

export const SocialAdsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.title'),
  type: 'object',
  required: [
    'targetPhoneNumber',
    'selectACallToActionButton'
  ],
  properties: {
    facebookPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlLabel'),
      default: data?.facebookPageUrl || '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.landingPageUrlLabel'),
      default: data?.landingPageUrl || '',
    },
    targetPhoneNumber: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.targetPhoneNumberLabel'),
      default: data?.targetPhoneNumber || '',
    },
    selectACallToActionButton: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.selectACallToActionButtonLabel'),
      default: data?.selectACallToActionButton || '',
      enum: [
        'No Button',
        'Apply Now',
        'Book Now',
        'Contact Us',
        'Donate Now',
        'Download',
        'Get Showtimes',
        'Learn More',
        'Listen Now',
        'Request Time',
        'See Menu',
        'Send Message',
        'Shop Now',
        'Sign Up',
        'Watch Now',
      ],
    },
    useAutomaticOrOptimizedPlacementOnAds: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.useAutomaticOrOptimizedPlacementOnAdsLabel'),
      default: data?.useAutomaticOrOptimizedPlacementOnAds || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },

  },
  dependencies: {
    useAutomaticOrOptimizedPlacementOnAds: {
      oneOf: [
        {
          properties: {
            useAutomaticOrOptimizedPlacementOnAds: {
              enum: ['No'],
            },
            whereDoesTheClientWantTheirAdsToAppear: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.whereDoesTheClientWantTheirAdsToAppearLabel'),
              default: data?.whereDoesTheClientWantTheirAdsToAppear || '',
              items: {
                type: 'string',
                enum: [
                  'Facebook Feed',
                  'Facebook Instant Articles',
                  'Facebook In-stream Videos',
                  'Facebook Marketplace',
                  'Facebook Stories',
                  'Facebook Search Results',
                  'Story Ads',
                  'Instagram Stories',
                  'Instagram Feed',
                  'Instagram Explore'
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
  },
});

const phoneNumberTextFieldKeys = [
  'targetPhoneNumber',
];

const urlTextFieldKeys = [
  'facebookPageUrl',
  'landingPageUrl',
];

const checkboxesFieldKeys = [
  'whereDoesTheClientWantTheirAdsToAppear',
];

const phoneNumberFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'phoneNumberTextField'}
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});

const checkboxesFields = checkboxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'}
}), {});


export const SocialAdsUISchema = {
  'ui:order': [
    'facebookPageUrl',
    'facebookPageUrlHelperText',
    'landingPageUrl',
    'targetPhoneNumber',
    'selectACallToActionButton',
    'useAutomaticOrOptimizedPlacementOnAds',
    'whereDoesTheClientWantTheirAdsToAppear',
  ],
  ...urlTextFields,
  ...phoneNumberFields,
  ...checkboxesFields,
  selectACallToActionButton: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.socialAdsLeadAds.selectACallToActionButtonErrorMessage')}/>
  },
  useAutomaticOrOptimizedPlacementOnAds: {
    'ui:widget': (props) => <Dropdown {...props}/>
  },
};

export const SocialAdsCustomValidate = (formData: any, errors, uiSchema) => {
  if ((formData?.landingPageUrl.length > 0) && !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.landingPageUrlErrorMessage'));
    errors.addError(true);
  }
  if (!formData?.targetPhoneNumber || formData?.targetPhoneNumber.trim() === '') {
    errors.targetPhoneNumber.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.targetPhoneNumberErrorMessage'));
    errors.addError(true);
  }
  if ((formData?.facebookPageUrl.length > 0 )&& !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
