/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import {
  SpecialAdCategoriesCustomValidate,
  SpecialAdCategoriesSchema,
  SpecialAdCategoriesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/SpecialAdCategoriesSchema';
import {
  ContactInformationCustomValidate,
  ContactInformationSchema,
  ContactInformationUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ContactInformationSchema';
import {
  SocialAdsWebClickCustomValidateChange,
  SocialAdsWebClickSchema,
  SocialAdsWebClickUIOrderSchemaChange,
} from 'pages/generic-product/details/product-schema/SocialAdsWebsiteClicksSchemaChild';
import {
  SocialAdsWebClickWithDetailedTargetingCustomValidateChange,
  SocialAdsWebClickWithDetailedTargetingSchema,
  SocialAdsWebClickWithDetailedTargetingUIOrderSchemaChange,
} from 'pages/generic-product/details/product-schema/SocialAdsWebsiteClicksWithDetailedTargetingChild';
import {
  WebsitesCustomValidate,
  WebsitesSchema,
  WebsitesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/WebsitesSchema';
import {
  ProxyOrProvisioningCustomValidate,
  ProxyOrProvisioningSchema,
  ProxyOrProvisioningUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ProxyOrProvisioningSchema';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/NotesAndInstructionsSchema';
import {
  FacebookCreativeInstructionsCustomValidate,
  FacebookCreativeInstructionsSchema,
  FacebookCreativeInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/FacebookCreativeInstructionsSchema';
import {
  ImageryVideoCustomValidate,
  ImageryVideoSchema,
  ImageryVideoUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ImageryVideoSchema';

const { t } = i18n;

export const SocialAdsWebsiteClicksWithDetailedTargetingSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.title'),
      schema: SpecialAdCategoriesSchema(data),
      uiSchema: SpecialAdCategoriesUISchema(data),
      customValidate: SpecialAdCategoriesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.websites.title'),
      schema: WebsitesSchema(data),
      uiSchema: WebsitesUISchema,
      customValidate: WebsitesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
      schema: ProxyOrProvisioningSchema(data),
      uiSchema: ProxyOrProvisioningUISchema(data),
      customValidate: ProxyOrProvisioningCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.contactInformation.title'),
      schema: ContactInformationSchema(data),
      uiSchema: ContactInformationUISchema,
      customValidate: ContactInformationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.socialAdsWebClicksSection.SocialAdsWebsiteTitle'),
      schema: SocialAdsWebClickSchema(data),
      uiSchema: SocialAdsWebClickUIOrderSchemaChange,
      customValidate: SocialAdsWebClickCustomValidateChange,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.headerLabel'),
      schema: SocialAdsWebClickWithDetailedTargetingSchema(data),
      uiSchema: SocialAdsWebClickWithDetailedTargetingUIOrderSchemaChange,
      customValidate: SocialAdsWebClickWithDetailedTargetingCustomValidateChange,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.title'),
      schema: FacebookCreativeInstructionsSchema(data),
      uiSchema: FacebookCreativeInstructionsUISchema,
      customValidate: FacebookCreativeInstructionsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  if (data.isOrange) {
    const indexToInsert = 7;
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.title'),
      schema: ImageryVideoSchema(data),
      uiSchema: ImageryVideoUISchema,
      customValidate: ImageryVideoCustomValidate,
    });
  }

  return schema;
};

export const SocialAdsWebsiteClicksWithDetailedTargetingUISchema = (data) => ({
  'ui:order': [
    ...SpecialAdCategoriesUISchema(data)['ui:order'],
    ...WebsitesUISchema['ui:order'],
    ...ProxyOrProvisioningUISchema(data)['ui:order'],
    ...ContactInformationUISchema['ui:order'],
    ...SocialAdsWebClickUIOrderSchemaChange['ui:order'],
    ...SocialAdsWebClickWithDetailedTargetingUIOrderSchemaChange['ui:order'],
    ...FacebookCreativeInstructionsUISchema['ui:order'],
    ...ImageryVideoUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsWebsiteClicksWithDetailedTargetingCustomValidate = (formData: any, errors, uiSchema) => errors;
