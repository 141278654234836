import { FormProvider, useForm } from 'react-hook-form';
import {
  IQAddressMapbox,
  IQFormInput,
  IQFormSelect,
  IQTheme,
  IQFormTextArea,
  IQInputEmail,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Box, Button, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import NeSelectChip from 'components/chips/NeSelectChip';
import useMapboxService from 'services/MapboxService';
import ChatSilverType, { LocationType } from 'models/ChatSilverType';
import NeCheckbox from 'components/checkbox/NeCheckbox';
import { setServiceAreaLabels } from 'pages/orders/utils';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { putProduct } from 'services/productsSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import VerificationModal from './VerificationModal';
import schema from './LocationCardForm.schema';
import LeadEmailsForm from './LeadEmailsForm';

interface Props {
  locations: ChatSilverType['locations']
  location: Partial<LocationType>;
  setLocations: (data) => any;
  setHasError: (data) => any;
  setIsFormDirty: (data) => any;
}

export default function LocationCardForm(props: Props) {
  const { t } = useTranslation();
  const {
    location, locations, setLocations, setHasError, setIsFormDirty,
  } = props;
  const dispatch = useDispatch();
  const { orderItemId } = useParams();
  const [addresses, setAddresses] = useState<any>([]);
  const [places, setPlaces] = useState<any>([]);
  const [selectedPlaces, setSelectedPlaces] = useState<any>([]);
  const [openVerificationModal, setOpenVerificationModal] = useState<boolean>(false);
  const deBouncerRef = useRef<any>(null);

  const {
    getAddresses, countryItems, getServiceAreasList, countryNamesConversion, twoLettersCountryEquivalent,
  } = useMapboxService();

  const setCountry = (location) => {
    let locationLength = 0;
    if (location) { locationLength = location.length; }
    switch (locationLength) {
      case 2:
        return location;
      case 3:
        return twoLettersCountryEquivalent[location];
      default:
        return countryNamesConversion[location] || 'US';
    }
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      addressOne: location?.addressOne || '',
      addressTwo: location?.addressTwo || '',
      city: location?.city || '',
      stateProvinceRegion: location?.stateProvinceRegion || '',
      zipcode: location?.zipcode || '',
      country: setCountry(location?.country) || 'US',
      doNotDisclose: location?.doNotDisclose || false,
      serviceAreas: setServiceAreaLabels(location?.serviceAreas) || [],
      serviceAreaNotes: location?.serviceAreaNotes.replaceAll('\\n', '\n')
        .replaceAll('\\', '') || '',
      chatListedHoursOfOperation: location?.chatListedHoursOfOperation?.replaceAll('\\n', '\n')
        .replaceAll('\\', '') || '',
      chatListedSupportEmail: location?.chatListedSupportEmail || '',
      emails: location?.emails || [],
    },
  });

  const {
    setValue, register, getValues, handleSubmit, trigger,
    watch, setFocus, formState: {
      isValid, isDirty,
    },
  } = methods;

  const country = watch('country');
  const emails = watch('emails');

  const updateLocationsData = (formValues) => {
    const copyLocations = [...locations];
    const updatedLocations = copyLocations.map(item =>
      ((item.locationId === location.locationId ? { ...item, ...formValues, serviceAreas: selectedPlaces } : item)));
    setLocations(updatedLocations);
    return updatedLocations;
  };

  const getAddressObj = (locObj) => ({
    addressOne: locObj?.addressOne || '',
    addressTwo: locObj?.addressTwo || '',
    city: locObj?.city || '',
    stateProvinceRegion: locObj?.stateProvinceRegion || '',
    zipcode: locObj?.zipcode || '',
    country: locObj?.country || '',
  });

  const onSubmit = async () => {
    const updatedLocationsData = updateLocationsData(getValues());
    const formValues = getValues();
    const isAddressChanged = !(_.isEqual(getAddressObj(location), getAddressObj(formValues)));
    if (isAddressChanged) {
      setOpenVerificationModal(true);
    } else {
      dispatch(putProduct({
        orderItemId, content: { locations: updatedLocationsData },
      }));
    }
  };

  const autoCompleteAddress = (address) => {
    setValue('addressOne', address.address);
    setValue('stateProvinceRegion', address.region);
    setValue('city', address.place);
    setValue('zipcode', address.postalCode);
    setValue('country', twoLettersCountryEquivalent[address.country]);
  };

  const isLeadEmailEmpty = (emails.length === 0);

  useEffect(() => {
    if (!isValid || isLeadEmailEmpty) {
      updateLocationsData({ status: 'Review' });
      setHasError(true);
    } else {
      updateLocationsData({ status: 'Complete' });
      setHasError(false);
    }
  }, [isValid, isLeadEmailEmpty]);

  useEffect(() => {
    if (isDirty) {
      setHasError(true);
      setIsFormDirty(true);
    }
  }, [isDirty]);

  useEffect(() => {
    const values = getValues('serviceAreas');
    if (values.length === 0) {
      setSelectedPlaces([]);
    } else {
      const jsonObj = values.map(el => ({ label: el, value: el }));
      setSelectedPlaces(jsonObj);
    }
  }, [watch('serviceAreas')]);

  useEffect(() => {
    setFocus('serviceAreas');
  }, [setFocus]);

  const getSuggestions = async (e) => {
    try {
      const address = await getServiceAreasList(e.query, country);
      // since locations getting the text array, we only save label
      const updatedOptions = address.map(el => ({label: el.label, value: el.label}));
      setPlaces(updatedOptions);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (e: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(() => {
      getSuggestions(e);
    }, 1500);
  };

  const getSuggestionsForLocation = (e: any) => {
    if (deBouncerRef.current) {
      clearTimeout(deBouncerRef.current);
    }
    deBouncerRef.current = setTimeout(async () => {
      try {
        const address = await getAddresses(e.query, country);
        setAddresses(address.data?.suggestions);
      } catch (error) {
        console.error('Error:', error);
      }
    }, 1500);
  };

  const customValidation = yup.string()
    .email(t('pages.business.client.edit.emailError'))
    .required(t('pages.business.client.edit.emailError'));

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box component="form" display="flex" width="80%"
          sx={{ alignItems: 'center', margin: 'auto' }}
        >
          <GridContainer fullWidth>
            <GridItem>
              <Typography variant="h5" component="h2" mt={5}>
                Address
              </Typography>
            </GridItem>
            <GridItem>
              <IQAddressMapbox
                getAddressOptions={getSuggestionsForLocation}
                items={addresses}
                labelText="Address Line 1"
                onAutoCompleteSelected={autoCompleteAddress}
                name="addressOne"
                onAddressChange={() => {}}
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="addressTwo"
                name="addressTwo"
                labelText="Address Line 2"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem inlineItems>
              <IQFormInput
                id="city"
                name="city"
                labelText="City *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <IQFormInput
                id="stateProvinceRegion"
                name="stateProvinceRegion"
                labelText="State / Province / Region *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem inlineItems>
              <IQFormInput
                id="zipcode"
                name="zipcode"
                labelText="Zip / Postal Code *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <IQFormSelect
                id="country"
                name="country"
                labelText="Country *"
                items={countryItems}
                defaultValue="US"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <NeCheckbox
                label="Do not disclose address"
                name="doNotDisclose"
                field="doNotDisclose"
                tooltipText="Select this ONLY if you do not want to disclose the address within the Chat Script."
                tooltipPlacement="top"
                tooltipArrow={false}
                tooltipInline
              />
            </GridItem>
            <GridItem>
              <Typography variant="h5" component="h2">
                Service Area
              </Typography>
            </GridItem>
            <GridItem>
              <NeSelectChip label labelText="Service Area" items={places}
                schema={schema}
                name="serviceAreas" placeholder="Type and enter to add an item"
                values={[]} maxItems={20} freeSolo={false}
                disableClear required
                theme={IQTheme}
                getSuggestions={(e) => handleInputChange(e)}
                supportCustomChips={false}
                onClose={() => trigger('serviceAreas')}
                labelTooltipText="Fill out with the specific area your business services,
                or if you want to highlight that the business services everyone in a particular region, or nationwide."
              />
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="serviceAreaNotes"
                labelText="Service Area Notes"
                name="serviceAreaNotes"
                rowCount={2}
                fullWidth
                fontLabelWeight="600"
              />
            </GridItem>
            <GridItem>
              <Typography variant="h5" component="h2">
                Hours of Operation
              </Typography>
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="chatListedHoursOfOperation"
                labelText="Hours of Operation"
                name="chatListedHoursOfOperation"
                rowCount={2}
                fullWidth
                fontLabelWeight="600"
              />
            </GridItem>
            <GridItem>
              <Typography variant="h5" component="h2">
                Customer Support Email
              </Typography>
            </GridItem>
            <GridItem>
              <IQInputEmail
                labelText="Support Email *"
                fontLabelWeight="600"
                theme={IQTheme}
                schema={schema}
                label=""
                field="chatListedSupportEmail"
                fullWidth
                // eslint-disable-next-line max-len
                tooltipText="Required. This is the email address that will  be shown to chatters inquiring about Contact Information."
                tooltipPlacement="top"
                tooltipPaddingBottom={16}
                adornmentIcon={<MailOutlineIcon />}
                tooltipArrow={false}
                customValidation={customValidation}
              />
            </GridItem>
            <GridItem>
              <LeadEmailsForm
                register={register}
              />
            </GridItem>
            <GridItem>
              <Button name="save" type="submit" variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isLeadEmailEmpty}
              >
                Save Location
              </Button>
            </GridItem>
            <VerificationModal
              openModal={openVerificationModal}
              setOpenModal={setOpenVerificationModal}
              formData={{ ...getValues(), serviceAreas: selectedPlaces }}
              locations={locations}
              location={location}
            />
          </GridContainer>
        </Box>
      </FormProvider>
    </ValidationProvider>
  );
}
