import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;
const urlValidationParameter = ['https://facebook.com','https://www.facebook.com','https://fb.me', 'https://fb.com'];

export const SocialAdsCustomStepperSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.socialAdsCustom.title'),
  type: 'object',
  required: [
    'selectACallToActionButton'
  ],
  properties: {
    facebookPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlLabel'),
      default: data?.facebookPageUrl || '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.landingPageUrlLabel'),
      default: data?.landingPageUrl || '',
    },
    businessDifferentiators: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.businessDifferentiators'),
      default: data?.targetPhoneNumber || '',
    },
    selectACallToActionButton: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.selectACallToActionButtonLabel'),
      default: data?.selectACallToActionButton || '',
      enum: [
        'No Button',
        'Apply Now',
        'Book Now',
        'Contact Us',
        'Donate Now',
        'Download',
        'Get Showtimes',
        'Learn More',
        'Listen Now',
        'Request Time',
        'See Menu',
        'Send Message',
        'Shop Now',
        'Sign Up',
        'Watch Now',
      ],
    },
  },
});

const textFieldKeys = [
  'businessDifferentiators'
]

const urlTextFieldKeys = [
  'facebookPageUrl',
  'landingPageUrl',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});


export const SocialAdsCustomStepperUISchema = {
  'ui:order': [
    'facebookPageUrl',
    'landingPageUrl',
    'businessDifferentiators',
    'selectACallToActionButton',
  ],
  ...textFields,
  ...urlTextFields,
  selectACallToActionButton: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.socialAdsLeadAds.selectACallToActionButtonErrorMessage')}/>
  },
};

export const SocialAdsCustomStepperCustomValidate = (formData: any, errors, uiSchema) => {
  if ((formData?.facebookPageUrl.length > 0) && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  if ((formData?.landingPageUrl.length > 0) && !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.landingPageUrlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};