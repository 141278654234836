import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  Typography, RadioGroup, FormControlLabel, Radio, TextField, IconButton, Tooltip, Link,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import { Trans } from 'react-i18next';
import schema from '../GeneralQuestions.schema';
import { hideNumberFieldArrows } from '../GeneralQuestionsForm';
import { emailFrequencyOptions } from '../generalQuestionsData';

const { t } = i18n;

const productsWithoutCPL = [
  'ChatProduct',
  'ChatPlatinumProduct',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SEOTopicLocation',
  'SEOContentPages',
  'SMMFacebookPosts',
  'SMMInstagram',
  'SMMX',
  'SMMPinterest',
  'SMMInfographics',
  'PiQLandingPages',
  'RLBrandedContent',
  'SEOCustom'
];

export const tooltip = (title, icon, width = '300px') => (
  <Tooltip title={title} placement="top"
    PopperProps={{
      sx: {
        '& .MuiTooltip-tooltip': {
          background: IQThemeV2.palette.text.primary,
          marginBottom: '4px !important',
          fontSize: '12px',
          fontWeight: 400,
          width,
        },
      },
    }}
  >
    <IconButton size="small"
      sx={{
        paddingRight: '0',
        '& .MuiSvgIcon-root': { color: IQThemeV2.palette.primary.main, fontSize: '18px' },
      }}
    >
      {icon}
    </IconButton>
  </Tooltip>
);

export const Tracking = (register, errors, values, offeringType, isPiqPunchListOrder) => {

  const offeringTypeToHideEmailPreferenceField = [
    'CustomReporting',
    'DMSNextCustomerCenterByDash',
    'SMMInfographics',
    'SEOTopicLocation',
    'ChatProduct',
    'ChatPlatinumProduct',
  ];

  const offeringTypeToHideAverageTransactionValueField = [
    'SMMInfographics',
    'SEOTopicLocation',
    'SEOCustom',
    'ChatProduct',
    'ChatPlatinumProduct',
  ];

  const hasErrorInCostPerLeadField = !!errors?.costPerLead;
  const offeringTypeToDisableTracking = offeringType === 'VideoCreativeServicePackage' || offeringType === 'DisplayCreativeServices' || offeringType === 'LandingPage';
  const isOfferingTypeToHideEmailPreference = offeringTypeToHideEmailPreferenceField.includes(offeringType);
  const costPerLeadErrorMsg = hasErrorInCostPerLeadField ? errors.costPerLead.message : '';

  const hasErrorAvgTransactionField = !!errors?.averageTransactionValue;
  const avgTransactionFieldErrorMsg = hasErrorAvgTransactionField ? errors.averageTransactionValue.message : '';
  const isProductWithoutAverageTransactionValue = offeringTypeToHideAverageTransactionValueField.includes(offeringType);

  // eslint-disable-next-line max-len
  const campaignPerformancEmailUrl = 'https://3594075dd4.rlsso.reachlocal.com/register/help/campaign/campaign_daily_leads_email.php';

  const getTooltipForEmailOption = (name) => {
    switch (name) {
      case 'Off - No Emails':
        return t('pages.generalQuestions.tracking.emailOption1Tooltip');
      case 'On - Email Agency Only':
        return t('pages.generalQuestions.tracking.emailOption3Tooltip');
      case 'On - Email Advertiser Only':
        return t('pages.generalQuestions.tracking.emailOption2Tooltip');
      case 'On - Email Both Agency & Advertiser':
        return t('pages.generalQuestions.tracking.emailOption4Tooltip');
      default: return '';
    }
  };

  return (
    <>
      <GridItem marginBottom={3} marginTop={2}>
        <Typography variant="h5" component="h2">
          {t('pages.generalQuestions.tracking.title')}
        </Typography>
      </GridItem>
      {!isPiqPunchListOrder && (
      <>
        <GridItem marginBottom={1}>
          {!isOfferingTypeToHideEmailPreference && (
              <GridItem marginBottom={0}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.generalQuestions.tracking.email')}
                  {tooltip(<Trans
                      i18nKey="pages.generalQuestions.tracking.emailTooltip"
                      values={{url: campaignPerformancEmailUrl}}
                      components={{
                        a: <Link
                            style={{textDecorationColor: IQThemeV2.palette.common.white}}
                            color="inherit"
                            underline="always"
                            href={campaignPerformancEmailUrl}
                            target="_blank" rel="noopener noreferrer"
                        />,
                      }}
                  /> as unknown as string, <HelpOutlineOutlinedIcon/>)}
                </Typography>
                <RadioGroup
                    aria-labelledby="email-preference"
                    name="emailPreference"
                    value={values.emailPreference}
                    onChange={(e) => values.setEmailPreference(e.target.value)}
                >
                  <GridItem sizes={[5, 6]} marginBottom={3}>
                    {['Off - No Emails', 'On - Email Agency Only'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            {...register('emailPreference', {
                              validate: (value) => schemaValidate(value, 'emailPreference', schema),
                            })}
                            value={opt}
                            control={<Radio/>}
                            label={(
                                <Typography variant="body1">
                                  {opt}
                                  {tooltip(getTooltipForEmailOption(opt), <InfoOutlinedIcon/>)}
                                </Typography>
                            )}
                        />
                    ))}
                    {['On - Email Advertiser Only', 'On - Email Both Agency & Advertiser'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            {...register('emailPreference', {
                              validate: (value) => schemaValidate(value, 'emailPreference', schema),
                            })}
                            value={opt}
                            control={<Radio/>}
                            label={(
                                <Typography variant="body1">
                                  {opt}
                                  {tooltip(getTooltipForEmailOption(opt), <InfoOutlinedIcon/>)}
                                </Typography>
                            )}
                        />
                    ))}
                  </GridItem>
                </RadioGroup>
              </GridItem>
          )}
        </GridItem>

        {values.showEmailFrequency && (
          <GridItem marginBottom={3}>
            <GridItem marginBottom={0}>
              <Typography variant="subtitle2" component="h3" fontSize={16}>
                {t('pages.generalQuestions.tracking.emailFrequency')}
              </Typography>
              <RadioGroup
                aria-labelledby="email-frequency"
                name="emailFrequency"
                value={values.emailFrequency}
                onChange={(e) => values.setEmailFrequency(e.target.value)}
              >
                {emailFrequencyOptions.map(opt => (
                  <FormControlLabel
                    key={opt}
                    {...register('emailFrequency', {
                      validate: (value) => schemaValidate(value, 'emailFrequency', schema),
                    })}
                    value={opt}
                    control={<Radio />}
                    label={opt}
                  />
                ))}
              </RadioGroup>
            </GridItem>
          </GridItem>
        )}

        {!offeringTypeToDisableTracking && (
            <>
              <GridItem marginBottom={1}>
                <GridItem marginBottom={0}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.generalQuestions.tracking.method')}
                  </Typography>
                  <RadioGroup
                      aria-labelledby="tracking-method"
                      name="trackingMethod"
                      value={values.trackingMethod}
                      onChange={(e) => values.setTrackingMethod(e.target.value)}
                  >
                    <GridItem sizes={[5, 6]} marginBottom={3}>
                      {['ReachCode', 'Third-Party Proxy'].map(opt => (
                          <FormControlLabel
                              key={opt}
                              {...register('trackingMethod', {
                                validate: (value) => schemaValidate(value, 'trackingMethod', schema),
                              })}
                              value={opt}
                              control={<Radio/>}
                              label={opt}
                          />
                      ))}
                      {['Vertical Specific Proxy', 'DWL - No Tracking'].map(opt => (
                          <FormControlLabel
                              key={opt}
                              {...register('trackingMethod', {
                                validate: (value) => schemaValidate(value, 'trackingMethod', schema),
                              })}
                              control={<Radio value={opt}/>}
                              label={opt}
                          />
                      ))}
                    </GridItem>
                  </RadioGroup>
                </GridItem>
              </GridItem>

              <GridItem sizes={[4, 4]} marginBottom={3}>
                {!isProductWithoutAverageTransactionValue && (<GridItem marginBottom={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                              color={errors.averageTransactionValue ? IQThemeV2.palette.error.main : '#000000'}
                  >
                    {t('pages.generalQuestions.tracking.average.title')}
                    {tooltip(t('pages.generalQuestions.tracking.average.tooltip'), <InfoOutlinedIcon/>)}
                  </Typography>
                  <GridItem marginBottom={0}>
                    <TextField
                        hiddenLabel
                        required
                        name="averageTransactionValue"
                        type="number"
                        fullWidth
                        error={!!errors.averageTransactionValue}
                        sx={hideNumberFieldArrows}
                        InputProps={{
                          startAdornment: <Typography variant="body1">$</Typography>,
                        }}
                        {...register('averageTransactionValue', {
                          validate: (value) => schemaValidate(value, 'averageTransactionValue', schema),
                        })}
                    />
                    <ErrorText
                        hasError={hasErrorAvgTransactionField}
                        errorText={avgTransactionFieldErrorMsg}
                    />
                  </GridItem>
                </GridItem>)}
                {!productsWithoutCPL.includes(offeringType) && (
                    <GridItem marginBottom={1}>
                      <Typography variant="subtitle2" component="h3" fontSize={16}
                                  color={errors.costPerLead ? IQThemeV2.palette.error.main : '#000000'}
                      >
                        {t('pages.generalQuestions.tracking.costPerLead.title')}
                        {tooltip(t('pages.generalQuestions.tracking.costPerLead.tooltip'), <InfoOutlinedIcon/>)}
                      </Typography>
                      <GridItem marginBottom={0}>
                        <TextField
                            hiddenLabel
                            required
                            name="costPerLead"
                            type="number"
                            fullWidth
                            error={!!errors.costPerLead}
                            sx={hideNumberFieldArrows}
                            InputProps={{
                              startAdornment: <Typography variant="body1">$</Typography>,
                            }}
                            {...register('costPerLead', {
                              validate: (value) => schemaValidate(value, 'costPerLead', schema),
                            })}
                        />
                        <ErrorText
                            hasError={hasErrorInCostPerLeadField}
                            errorText={costPerLeadErrorMsg}
                        />
                      </GridItem>
                    </GridItem>
                )}
              </GridItem>
            </>
        )}
      </>
      )}
    </>
  );
};
