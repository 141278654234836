import * as yup from 'yup';
import { IQButtonLink, IQInputEmail } from '@gannettdigital/shared-react-components';
import { Button, Grid, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import CoModalConfirm from 'components/modals/CoModalConfirm';

interface Props {
  getValues: any
  setValue: any
  schema: any
}

export default function ClientEmails({ getValues, setValue, schema }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState({ email: '', index: -1 });

  const { fields: emailsArray, remove: removeEmail } = useFieldArray({
    name: 'contactEmailList',
  });

  const onEmailAdd = () => {
    const prevList = getValues('contactEmailList');
    prevList.push('');
    setValue('contactEmailList', prevList);
  };

  const onEmailDelete = (index) => {
    const email = getValues('contactEmailList')[index];
    setShowDeleteEmailDialog(true);
    setEmailToDelete({ email, index });
  };

  const confirmEmailDelete = () => {
    removeEmail(emailToDelete.index);
    setShowDeleteEmailDialog(false);
  };

  const cancelEmailDelete = () => {
    setShowDeleteEmailDialog(false);
    setEmailToDelete({ email: '', index: -1 });
  };

  const customValidation = yup.string()
    .email(t('pages.business.client.edit.emailError'))
    .required(t('pages.business.client.edit.emailError'));

  const emailRow = (email, key) => (
    <Grid item xs={12} display="flex"
      flexDirection="row" key={key}
    >
      <Grid item xs={key === 0 ? 12 : 11}>
        <IQInputEmail
          name={`contactEmailList.${key}`}
          field={`contactEmailList.${key}`}
          labelText={t('pages.business.client.edit.emailLabel')}
          fontLabelWeight="600"
          theme={theme}
          schema={schema}
          required
          label={t('pages.business.client.edit.emailLabel')}
          fullWidth
          adornmentIcon={<MailOutlineIcon />}
          customValidation={customValidation}
        />
      </Grid>
      { key !== 0 && (
        <Grid item xs={1}
          display="flex"
          alignItems="flex-end"
        >
          <Button onClick={() => onEmailDelete(key)} sx={{ color: theme.palette.primary.main }}>
            <DeleteIcon />
          </Button>
        </Grid>
      ) }
    </Grid>
  );

  return (
    <>
      { emailsArray.map((email, index) => emailRow(email, index)) }
      <Grid item xs={12}>
        <IQButtonLink startIcon={<AddIcon />} onClick={onEmailAdd}>
          {t('pages.business.client.edit.addEmailLabel')}
        </IQButtonLink>
      </Grid>
      <CoModalConfirm open={showDeleteEmailDialog}
        title={t('pages.business.client.removeModal.title')}
        description={(
          <Trans
            i18nKey="pages.business.client.removeModal.emailMessage"
            values={{ email: emailToDelete.email ? `"${emailToDelete.email}"` : '' }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText="Remove"
        handleClose={cancelEmailDelete}
        handleConfirm={confirmEmailDelete}
      />
    </>
  );
}
