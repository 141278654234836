import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18next';
import TextField from 'pages/generic-product/details/custom-fields/TextField';

const { t } = i18n;

export const GA4Schema: RJSFSchema | any = () => ({
  title: 'GA4',
  type: 'object',
  properties: {
    isThisTiedToBrandAmplifier: {
      type: 'boolean',
      title: t('pages.ga4.isThisTiedToBrandAmplifierLabel'),
      default: false,
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.ga4.notesAndInstructionsLabel'),
      default: '',
    },
  },
});

export const GA4UISchema = {
  'ui:order': ['isThisTiedToBrandAmplifier', 'notesOrInstructions'],
  isThisTiedToBrandAmplifier: {
    'ui:widget': 'checkbox',
  },
  notesOrInstructions: {
    'ui:widget': (props: any) => <TextField {...props} rows={12} />,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GA4CustomValidate = (formData: any, errors, uiSchema) => errors;
