import GridItem from 'components/grid/GridItem';
import { Box, Typography } from '@mui/material';
import { headingStyle } from 'pages/overview/sections/Business/BusinessSection';

type Props = {
  productData: any;
};

export default function XmoBrandAmplifierSectionExternal({
  productData,
}: Props) {
  const InventoryTierExternalMapping = {
    'Recommended - All Inclusive': 'Addressable Geofence - All Inventory (Image, Pre-roll, OTT, CTV)',
    'Representative - Excludes YouTube, CTV/OTT': 'Addressable Geofence - Image + Pre-roll',
    'Essential - Excludes YouTube, Pre-Roll Video, CTV/OTT': 'Addressable Geofence - Image Only',
  };

  return (
    <>
      {/* Solution Strategy */}
      <GridItem sizes={[3, 9]} marginBottom={3} sx={{
        padding: '20px 0 0',
        borderTop: '1px solid black',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
          >
            Solution Strategy
            <br />
            (Business Amplifier)
          </Typography>
        </Box>
        <Box>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            General
          </Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Goal Values</Typography>
                <Typography>{productData?.solutionStrategy?.goalValue}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Goal Comment</Typography>
                <Typography>
                  <Typography>
                    {productData?.solutionStrategy?.goalComment}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Inventory Tier</Typography>
                <Typography>
                  {InventoryTierExternalMapping[productData?.solutionStrategy?.inventoryTier] || ''}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Tactics */}
      <GridItem sizes={[3, 9]} marginBottom={3} sx={{
        padding: '20px 0 0',
        borderTop: '1px solid black',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Typography sx={{
            fontSize: '16px',
            fontWeight: '600',
          }}
          >
            Tactics
          </Typography>
        </Box>
        <Box>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400',
            mb: 1,
          }}
          >
            Website Tracking
          </Typography>
          <GridItem sizes={[6, 6]} sx={{ mt: 2 }} marginBottom={1}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business Website Homepage URL</Typography>
                <Typography>{productData?.solutionStrategy?.businessWebsiteUrl}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Political Campaign?</Typography>
                <Typography>{productData?.solutionStrategy?.politicalCampaign}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Client Success Manager Email</Typography>
                <Typography>{productData?.solutionStrategy?.csmEmail}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Website High Priority Page Urls</Typography>
                <Typography>{productData?.solutionStrategy?.websiteHighPriorityUrls}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Website Form Submission Urls</Typography>
                <Typography>{productData?.solutionStrategy?.websiteFormUrls}</Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );
}
