/* eslint-disable max-len */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const urlValidationParameter = ['https://www.facebook.com'];
const { t } = i18n;

export const SocialAdsRetargetingChildSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.retargeting.pageTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['callToAction'],
  properties: {
    facebookPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.facebookPageURL'),
      default: data?.facebookPageUrl || '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.landingPageURL'),
      default: data?.landingPageUrl || '',
    },
    callToAction: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.callToActionDropdown'),
      default: data?.callToAction || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.retargeting.noButtonOption'),
        t('pages.socialAds.solutionStrategy.retargeting.applyNowOption'),
        t('pages.socialAds.solutionStrategy.retargeting.bookNowOption'),
        t('pages.socialAds.solutionStrategy.retargeting.contactUsOption'),
        t('pages.socialAds.solutionStrategy.retargeting.donateNowOption'),
        t('pages.socialAds.solutionStrategy.retargeting.downloadOption'),
        t('pages.socialAds.solutionStrategy.retargeting.getShowtimeOption'),
        t('pages.socialAds.solutionStrategy.retargeting.learnMoreOption'),
        t('pages.socialAds.solutionStrategy.retargeting.listenNowOption'),
        t('pages.socialAds.solutionStrategy.retargeting.requestTimeOption'),
        t('pages.socialAds.solutionStrategy.retargeting.seeMenuOption'),
        t('pages.socialAds.solutionStrategy.retargeting.sendMessageOption'),
        t('pages.socialAds.solutionStrategy.retargeting.shopNowOption'),
        t('pages.socialAds.solutionStrategy.retargeting.signUpOption'),
        t('pages.socialAds.solutionStrategy.retargeting.watchNowOption'),
      ],
    },
    automaticOrOptimizedPlacement: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.automaticOrOptimizedPlacement'),
      default: data?.automaticOrOptimizedPlacement || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.retargeting.yesOption'),
        t('pages.socialAds.solutionStrategy.retargeting.noOption'),
      ],
    },
    areThereAnyBusinessDifferentiators: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.areThereAnyBusinessDifferentiatorsLabel'),
      default: data?.areThereAnyBusinessDifferentiators || '',
    },
    doesClientWantToEnableEntireWebsiteRetargetingOrFacebook: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.doesClientWantToEnableEntireWebsiteRetargetingOrFacebookLabel'),
      default: data?.doesClientWantToEnableEntireWebsiteRetargetingOrFacebook || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.entireWebsiteOptions'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.selectPagesOptions'),
      ],
    },
    daysOfClientRetargetVisitorsOnFacebook: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.daysOfClientRetargetVisitorsOnFacebookLabel'),
      default: data?.daysOfClientRetargetVisitorsOnFacebook || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.days15'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days30'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days60'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days90'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days120'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days150'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days180'),
      ],
    },
    clientWantToEnableLookalikeAudiences: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.clientWantToEnableLookalikeAudiencesLabel'),
      default: data?.clientWantToEnableLookalikeAudiences || '',
      enum: [
        '',
        t('Yes'),
        t('No'),
      ],
    },
    optOutLink: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.retargeting.optOutLinkLabel'),
      default: data?.optOutLink || '',
    },
  },

  dependencies: {
    automaticOrOptimizedPlacement: {
      oneOf: [
        {
          properties: {
            automaticOrOptimizedPlacement: {
              enum: ['No'],
            },
            clientAds: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.retargeting.clientAdsCheckbox'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.retargeting.mobileNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.retargeting.desktopNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.retargeting.instagramLabel'),
                  t('pages.socialAds.solutionStrategy.retargeting.facebookAudienceNetworkLabel'),
                  t('pages.socialAds.solutionStrategy.retargeting.marketplaceLabel'),
                  t('pages.socialAds.solutionStrategy.retargeting.storyAdsLabel'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            automaticOrOptimizedPlacement: {
              enum: ['Yes'],
            },
          },
        },
      ],
    },
    doesClientWantToEnableEntireWebsiteRetargetingOrFacebook: {
      oneOf: [
        {
          properties: {
            doesClientWantToEnableEntireWebsiteRetargetingOrFacebook: {
              enum: ['Entire Website'],
            },
            websiteUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.retargeting.websiteUrlLabel'),
              default: data?.websiteUrl || '',
            },
          },
        },
        {
          properties: {
            doesClientWantToEnableEntireWebsiteRetargetingOrFacebook: {
              enum: ['Select Pages'],
            },
            selectPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartRetargeting.selectPageURLTitle'),
              default: data?.selectPageUrl || '',
            },
          },
        },
      ],
    },
  },
});

const dropDownKeys = [
  'callToAction',
  'automaticOrOptimizedPlacement',
  'doesClientWantToEnableEntireWebsiteRetargetingOrFacebook',
  'daysOfClientRetargetVisitorsOnFacebook',
  'clientWantToEnableLookalikeAudiences',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textFieldKeys = [
  'areThereAnyBusinessDifferentiators',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const checkBoxesFieldKeys = [
  'clientAds',
];

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});
export const SocialAdsRetargetingChildUISchema = {
  'ui:order': [
    'facebookPageUrl', 'landingPageUrl', 'callToAction', 'automaticOrOptimizedPlacement', 'clientAds', 'areThereAnyBusinessDifferentiators',
    'doesClientWantToEnableEntireWebsiteRetargetingOrFacebook', 'websiteUrl', 'selectPageUrl', 'daysOfClientRetargetVisitorsOnFacebook',
    'clientWantToEnableLookalikeAudiences', 'optOutLink',
  ],

  websiteUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  optOutLink: {
    'ui:widget': 'UrlTextWidget',
  },
  selectPageUrl: {
    'ui:widget': 'textInputField',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.retargeting.selectPageURLHelper'),
  },
  facebookPageUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  landingPageUrl: {
    'ui:widget': 'UrlTextWidget',
  },

  ...dropDownFields,
  ...textFields,
  ...checkBoxesFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsRetargetingChildValidate = (formData: any, errors: any, uiSchema: any) => {
  if ((formData?.facebookPageUrl.length > 0) && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.socialAds.solutionStrategy.retargeting.facebookPageURLError'));
    errors.addError(true);
  }
  if ((formData?.landingPageUrl.length > 0) && !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.custom.solutions.common.landingPageURLError'));
    errors.addError(true);
  }

  return errors;
};
