/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from 'i18n/i18n';
import {
  FacebookCreativeInstructionsCustomValidate,
  FacebookCreativeInstructionsSchema,
  FacebookCreativeInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/FacebookCreativeInstructionsSchema';
import {
  ImageryVideoCustomValidate,
  ImageryVideoSchema,
  ImageryVideoUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ImageryVideoSchema';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/NotesAndInstructionsSchema';
import {
  ContactInformationCustomValidate,
  ContactInformationSchema,
  ContactInformationUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ContactInformationSchema';
import {
  SpecialAdCategoriesCustomValidate,
  SpecialAdCategoriesSchema,
  SpecialAdCategoriesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/SpecialAdCategoriesSchema';
import {
  WebsitesCustomValidate,
  WebsitesSchema,
  WebsitesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/WebsitesSchema';
import {
  ProxyOrProvisioningCustomValidate,
  ProxyOrProvisioningSchema,
  ProxyOrProvisioningUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ProxyOrProvisioningSchema';
import {
  SocialAdsCustomStepperCustomValidate,
  SocialAdsCustomStepperSchema,
  SocialAdsCustomStepperUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/SocialAdsCustomStepperSchema';
import {
  TimedEventsAndFlightsCustomValidate,
  TimedEventsAndFlightsSchema,
  TimedEventsAndFlightsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/TimedEventsAndFlightsSchema';
import {
  FacebookCampaignObjectiveCustomValidate,
  FacebookCampaignObjectiveSchema,
  FacebookCampaignObjectiveUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/FacebookCampaignObjectiveSchema';

const { t } = i18n;

export const SocialAdsCustomSchema = (data, item) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.title'),
      schema: SpecialAdCategoriesSchema(data, item),
      uiSchema: SpecialAdCategoriesUISchema(data),
      customValidate: SpecialAdCategoriesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.websites.title'),
      schema: WebsitesSchema(data),
      uiSchema: WebsitesUISchema,
      customValidate: WebsitesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
      schema: ProxyOrProvisioningSchema(data),
      uiSchema: ProxyOrProvisioningUISchema(data),
      customValidate: ProxyOrProvisioningCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.contactInformation.title'),
      schema: ContactInformationSchema(data),
      uiSchema: ContactInformationUISchema,
      customValidate: ContactInformationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.title'),
      schema: SocialAdsCustomStepperSchema(data),
      uiSchema: SocialAdsCustomStepperUISchema,
      customValidate: SocialAdsCustomStepperCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.timedEventsAndFlights'),
      schema: TimedEventsAndFlightsSchema(data),
      uiSchema: TimedEventsAndFlightsUISchema,
      customValidate: TimedEventsAndFlightsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjective'),
      schema: FacebookCampaignObjectiveSchema(data),
      uiSchema: FacebookCampaignObjectiveUISchema,
      customValidate: FacebookCampaignObjectiveCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.title'),
      schema: FacebookCreativeInstructionsSchema(data),
      uiSchema: FacebookCreativeInstructionsUISchema,
      customValidate: FacebookCreativeInstructionsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];
  if (data.isOrange) {
    const indexToInsert = 8;
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.title'),
      schema: ImageryVideoSchema(data),
      uiSchema: ImageryVideoUISchema,
      customValidate: ImageryVideoCustomValidate,
    });
  }
  return schema;
};

export const SocialAdsCustomUISchema = (data) => ({
  'ui:order': [
    ...SpecialAdCategoriesUISchema(data)['ui:order'],
    ...WebsitesUISchema['ui:order'],
    ...ProxyOrProvisioningUISchema(data)['ui:order'],
    ...ContactInformationUISchema['ui:order'],
    ...SocialAdsCustomStepperUISchema['ui:order'],
    ...TimedEventsAndFlightsUISchema['ui:order'],
    ...FacebookCampaignObjectiveUISchema['ui:order'],
    ...FacebookCreativeInstructionsUISchema['ui:order'],
    ...ImageryVideoUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const SocialAdsCustomCustomValidate = (formData: any, errors, uiSchema) => errors;
